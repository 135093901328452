import React, { useState, useEffect } from 'react'

import { Box } from 'components/layout/box'
import * as styles from './Leaderboard.css'
import { Text } from 'components/typography/text'

import { Leaderboard as LeaderboardTable } from 'components/Leaderboard'
import { Card } from 'components/Card'

import { fetchBotStats } from 'api/mavbots-arena'
import { useOktaAuth } from '@okta/okta-react'
import { useQuery } from '@tanstack/react-query'
import { useArenaContext } from 'context/ArenaContext'
import { fetchBotsImages } from 'api/mavbots-arena/fetchBotsImages'
import { tokens } from 'style/theme.css'

interface Props {}

export const Leaderboard = ({}: Props) => {
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()
	const { setAvatarImages } = useArenaContext()

	const { data: arenaBotsData } = useQuery({
		queryKey: ['mavbots-arena', 'mavbots'],
		queryFn: () => fetchBotStats(accessToken)
	})

	const { data: botImages } = useQuery({
		queryKey: ['bot-images', arenaBotsData?.map(bot => bot.avatar)],
		queryFn: () =>
			fetchBotsImages(
				accessToken,
				arenaBotsData?.map(bot => bot.avatar)
			),
		enabled: !!arenaBotsData
	})

	useEffect(() => {
		if (botImages) {
			setAvatarImages(botImages) // botImages is the object with bot file names as keys and base64 images as values
		}
	}, [botImages, setAvatarImages])

	return (
		<Box className={styles.mainContainer}>
			<Text variant="body1Bold">Leaderboard</Text>
			<Card
				style={{
					height: 'inherit',
					padding: '2px',
					marginLeft: '7px',
					marginRight: '7px',
					boxShadow: '-4px -4px 8px 0px rgb(255 255 255 / 11%), 4px 4px 8px 0px rgb(0 0 0 / 12%)'
				}}
				bodyStyle={{ borderRadius: '20px' }}>
				<LeaderboardTable />
			</Card>
		</Box>
	)
}

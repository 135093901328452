export const TopReflection = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="286" height="111" viewBox="0 0 286 111" fill="none">
		<g opacity="1" filter="url(#filter0_b_920_27284)">
			<path
				d="M283.591 0H48.984H0.0317688V48.9585V87.0175C-0.0397034 93.8239 0.0317688 101.615 0.0317688 110.5V87.0175C0.364319 55.3485 3.79147 45 31.9123 45C182.038 37.0456 286 11.2847 286 2.40892C286 1.07849 284.921 0 283.591 0Z"
				fill="url(#paint0_radial_920_27284)"
			/>
		</g>
		<defs>
			<filter
				id="filter0_b_920_27284"
				x="-2"
				y="-2"
				width="290"
				height="114.5"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
				<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_920_27284" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_920_27284" result="shape" />
			</filter>
			<radialGradient
				id="paint0_radial_920_27284"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="scale(282.506 104)">
				<stop offset="0.0001" stopColor="#D9D9D9" />
				<stop offset="0.0002" stopColor="#D9D9D9" />
				<stop offset="0.935" stopColor="#D9D9D9" stopOpacity="0" />
				<stop offset="1" stopColor="#010B08" stopOpacity="0" />
			</radialGradient>
		</defs>
	</svg>
)

import clsx from 'clsx'
import React from 'react'

import { NavLink, useMatch } from 'react-router-dom'

import * as styles from '../Sidebar.css'
import { MenuItem } from '../data'
import { Box } from 'components/layout/box'
import { BlockIcon } from 'components/icons/block-icon'
import { DoubleArrowIcon, MarketIcon } from 'assets/icons/icons'
import { IconButton } from 'components/inputs/icon-button'

interface MobileNavItemProps extends MenuItem {
	isOpen: boolean
	onToggle: (navId: string) => void
}

export const MobileNavItem = ({ to, mobileLabel, isOpen, subNav, icon, onToggle }: MobileNavItemProps) => {
	const match = useMatch(to || '')
	const isActiveLink = to ? match !== null : false
	const subNavMatches = subNav.map(item => useMatch(item.to || ''))
	const isOpenOrActive = isOpen || subNavMatches.some(Boolean)

	const toggleSubNav = (): void => {
		onToggle(mobileLabel)
	}

	if (to) {
		return (
			<NavLink to={to} className={styles.mobileNavLink}>
				<Box className={clsx(styles.mobileNavItem, isActiveLink && styles.mobileNavItemActive)}>
					<BlockIcon icon={icon} size="medium" />
					{mobileLabel}
				</Box>
			</NavLink>
		)
	}

	return (
		<Box
			className={clsx(styles.mobileNavItem, styles.mobileNavButton, isOpenOrActive && styles.mobileNavButtonActive)}
			onClick={toggleSubNav}>
			<Box className={styles.iconsWrapper}>
				<BlockIcon icon={icon} size="medium" />
				<Box className={styles.doubleArrowIcons}>
					<BlockIcon icon={DoubleArrowIcon} size="small" />
				</Box>
			</Box>
			{mobileLabel}
			{isOpen && subNav.length > 0 && (
				<Box className={styles.mobileNavSubItemContainer}>
					{subNav.map((item, index) => {
						const isSubNavItemActive = subNavMatches[index]
						return (
							<NavLink to={item.to} className={styles.mobileNavLink} key={item.to}>
								<Box className={clsx(styles.mobileNavSubItem, isSubNavItemActive && styles.mobileNavSubItemActive)}>
									<IconButton variant="mobileNavIcon" size="small">
										<BlockIcon icon={MarketIcon} size="small" />
									</IconButton>
									{item.label}
								</Box>
							</NavLink>
						)
					})}
				</Box>
			)}
		</Box>
	)
}

import { useMemo, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Box } from 'components/layout/box'
import { flexRender, useReactTable, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table'
import { leaderboardColumnsData } from './data'
import * as tableStyles from './Leaderboard.css'
import { TableInfoBox } from 'components/TableInfoBox'

import { fetchLeaderboard } from 'api/mavbots-arena'
import { useOktaAuth } from '@okta/okta-react'
import clsx from 'clsx'

interface Props {
	isTransparent?: boolean
	handleClick?: (botId: number) => void
}

export const Leaderboard = ({ isTransparent, handleClick }: Props) => {
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()

	const {
		data: laederboardData,
		isLoading,
		isError
	} = useQuery({
		queryKey: ['arena-leaderboard'],
		queryFn: () => fetchLeaderboard(accessToken)
	})

	// Memoize the data and columns
	const memoizedData = useMemo(() => laederboardData ?? [], [laederboardData])
	const memoizedColumns = useMemo(() => leaderboardColumnsData, [leaderboardColumnsData])

	const table = useReactTable({
		data: memoizedData,
		columns: memoizedColumns,
		debugTable: true,
		debugColumns: true,
		columnResizeMode: 'onChange',
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		initialState: {
			sorting: [{ id: 'rank', desc: false }]
		}
	})

	return (
		<Box display="flex" flexDirection="column" gap={4} overflow="auto" style={{ height: 'calc(100% - 24px)' }}>
			<Box display="flex">
				<div className={tableStyles.tableWrapper}>
					<table
						className={clsx(tableStyles.table, isTransparent && tableStyles.transparentTable)}
						style={{ overflowX: 'auto' }}>
						<thead className={tableStyles.tHead}>
							{table.getHeaderGroups().map(headerGroup => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map(header => {
										return (
											<th
												key={header.id}
												colSpan={header.colSpan}
												className={clsx(tableStyles.th, isTransparent && tableStyles.thWrap)}>
												{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
											</th>
										)
									})}
								</tr>
							))}
						</thead>
						<tbody className={tableStyles.tBody}>
							{isLoading && <TableInfoBox>Loading...</TableInfoBox>}
							{isError && <TableInfoBox>Error fetching data...</TableInfoBox>}
							{!isLoading && !isError && table.getRowModel().rows.length <= 0 && <TableInfoBox>No data</TableInfoBox>}
							{table.getRowModel().rows.map(row => (
								<tr key={row.id} className={tableStyles.dataRow}>
									{row.getVisibleCells().map(cell => {
										// Check if the column ID is 'rank' (or any specific column)
										const isRankColumn = cell.column.id === 'rank'

										return (
											<td
												key={cell.id}
												className={tableStyles.td}
												// Add onClick only for the rank column
												onClick={isRankColumn && handleClick ? () => handleClick(row.original.bot_id) : undefined}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										)
									})}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</Box>
		</Box>
	)
}

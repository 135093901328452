import React from 'react'

import { Box } from 'components/layout/box'
import * as styles from './BotProfileTabs.css'
import { Text } from 'components/typography/text'
import { Trader } from 'components/MavbotsArena/data/data'
import { TabsProvider } from 'components/Tabs/TabsProvider'
import { Tabs } from 'components/Tabs'

type RiskLevel = 1 | 2 | 3

const getRiskLevelText = (riskLevel: RiskLevel) => {
	switch (riskLevel) {
		case 1:
			return 'Low'
		case 2:
			return 'Medium'
		case 3:
			return 'High'
		default:
			return 'Unknown'
	}
}

interface BotProfileTabsProps {
	data: Trader
}

export const BotProfileTabs = ({ data }: BotProfileTabsProps) => {
	return (
		<Box>
			<TabsProvider defaultTab="Summary" tabs={['Summary', '10Year', 'RiskAdjusted']}>
				<Tabs>
					<Tabs.Tab value="Summary">Arena</Tabs.Tab>
					<Tabs.Tab value="10Year">10-year</Tabs.Tab>
					<Tabs.Tab value="RiskAdjusted">Risk adjusted</Tabs.Tab>

					<Tabs.Panel value="Summary">
						<Box className={styles.tabsLayout}>
							<SummaryTable data={data} />
						</Box>
					</Tabs.Panel>
					<Tabs.Panel value="10Year">
						<Box className={styles.tabsLayout}>
							<SummaryTable data={data} />
						</Box>
					</Tabs.Panel>
					<Tabs.Panel value="RiskAdjusted">
						<Box className={styles.tabsLayout}>
							<SummaryTable data={data} />
						</Box>
					</Tabs.Panel>
				</Tabs>
			</TabsProvider>
		</Box>
	)
}

const SummaryTable = ({ data }: BotProfileTabsProps) => {
	const tableData = [
		{
			label: 'YTD Performance',
			value: data.perf_ytd
		},
		{
			label: '5Y Performance',
			value: data.perf_5y
		},
		{
			label: 'Risk rating',
			value: getRiskLevelText(data.riskLevel)
		},
		{
			label: 'Total Return',
			value: '140.9%'
		},
		{
			label: 'Max Gross Exposure',
			value: '100%'
		},
		{
			label: 'Max Drawdown',
			value: '8%'
		},
		{
			label: 'Total Return',
			value: '140.9%'
		}
	]

	return (
		<Box className={styles.listTableWrapper}>
			{tableData.map((row, index) => (
				<TableRow key={index} label={row.label} value={row.value} />
			))}
		</Box>
	)
}

const TableRow = ({ label, value }: { label: string; value: string }) => {
	return (
		<Box className={styles.listRow}>
			<Text variant="body2Bold" color="neutral.100">
				{label}
			</Text>
			<Text variant="body2Bold" color="neutral.100">
				{value}
			</Text>
		</Box>
	)
}

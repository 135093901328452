import axios from 'axios'

export interface CumulativeReturns {
	time: string
	algo_perf: number
	benchmark_perf: number
}

export interface Badge {
	name: string
	icon: string
}

export interface Trader {
	bot_id: number
	bot_name: string
	description: string
	avatar: string
	videoAvatar: string
	riskLevel: 1 | 2 | 3
	badges: Badge[]
	strategy: string
	strategy_description: string
	trades: string[]
	open_positions: string[]
	perf_ytd: string
	perf_5y: string
	win_rate: string
	win_avg: string
	loss_rate: string
	loss_avg: string
	bot_details: {
		cumulative_returns: CumulativeReturns[]
		summary: {
			perf_ytd: string
		}
		ten_year: {}
		risk_adjusted: {}
	}
}

const axiosClient = axios.create()

axiosClient.defaults.headers.common = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Credentials': true,
	'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
}

export const fetchBotStats = async (accessToken: string | undefined): Promise<Trader[]> => {
	const response = await axiosClient.get(`${process.env.REACT_APP_API_SERVER}/storage/arena/stats`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})

	return response.data
}

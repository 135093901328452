import React, { createContext, useContext, useState, ReactNode } from 'react'
import logoutUser from 'helpers/logoutUser'
import oktaAuth from 'oktaAuthInstance'

// Define the context type for MainAppContext
interface MainAppContextType {
	userId: string | undefined
	setUserId: (userId: string) => void
	userGroup: string | undefined
	setUserGroup: (group: string) => void
	isSessionAllowedToShowData: boolean
	setSessionAllowedToShowData: (value: boolean) => void
	isSessionVerifiedAsCurrent: boolean
	setSessionVerifiedAsCurrent: (value: boolean) => void
	// User Guide
	loginCountGuide: number
	setLoginCountGuide: (value: number) => void
	// Loading while extending session
	loadingWhileExtendingSession: boolean
	setLoadingWhileExtendingSession: (value: boolean) => void
	// last graph time
	lastGraphUpdateTime: string
	setLastGraphUpdateTime: (value: string) => void
	// Invalidate user
	invalidateUser: (onlyOkta?: boolean) => void
}

const MainAppContext = createContext<MainAppContextType | undefined>(undefined)

// Create a custom hook to use the MainAppContext
export const useMainAppContext = () => {
	const context = useContext(MainAppContext)
	if (!context) {
		throw new Error('useMainAppContext must be used within a MainAppProvider')
	}
	return context
}

interface MainAppProviderProps {
	children: ReactNode
}

export const MainAppProvider = ({ children }: MainAppProviderProps) => {
	const [userId, setUserId] = useState<string | undefined>()
	const [userGroup, setUserGroup] = useState<string | undefined>()
	const [isSessionAllowedToShowData, setSessionAllowedToShowData] = useState<boolean>(
		localStorage.getItem('checked') === 'true' || false
	)
	const [isSessionVerifiedAsCurrent, setSessionVerifiedAsCurrent] = useState<boolean>(false)
	// User Guide
	const [loginCountGuide, setLoginCountGuide] = useState<number>(0)
	// Loading while extending session
	const [loadingWhileExtendingSession, setLoadingWhileExtendingSession] = useState<boolean>(
		localStorage.getItem('tokenUpdating') === 'true'
	)
	// last graph time
	const [lastGraphUpdateTime, setLastGraphUpdateTime] = useState('')

	// const oktaAuth = new OktaAuth(oktaAuthConfig)
	const invalidateUser = async (onlyOkta = false) => {
		const token = oktaAuth.getAccessToken()

		const logoutOkta = async () => {
			try {
				await oktaAuth.revokeRefreshToken()
				await oktaAuth.revokeAccessToken()

				// Get the idToken to pass to signOut

				// Get the idToken as an IDToken object
				const idToken = await oktaAuth.tokenManager.get('idToken')

				if (!idToken) {
					console.error('No idToken found in Token Manager')
					// Proceed with signOut without idToken if necessary
					await oktaAuth.signOut({
						postLogoutRedirectUri: `${window.location.origin}/logout`,
						clearTokensAfterRedirect: true
					})
					return
				}

				// Sign out from Okta and end the Okta session
				await oktaAuth.signOut({
					postLogoutRedirectUri: `${window.location.origin}/logout`,
					idToken, // Pass the idToken object
					clearTokensAfterRedirect: true
				})

				// Clear storage and session state after logout
				localStorage.removeItem('checked')
				localStorage.removeItem('email')
				sessionStorage.removeItem('sessionGuide')

				setSessionAllowedToShowData(false)
				setSessionVerifiedAsCurrent(false)
			} catch (error) {
				console.log('Failed to revoke tokens', error)

				// Ensure session state is cleared even if token revocation fails
				localStorage.removeItem('checked')
				localStorage.removeItem('email')
				sessionStorage.removeItem('sessionGuide')

				setSessionAllowedToShowData(false)
				setSessionVerifiedAsCurrent(false)
			}

			// window.location.href = '/login'
		}

		if (onlyOkta) {
			console.log('Logging out from Okta only')
			await logoutOkta()
		} else {
			console.log('Logging out user from backend and Okta')
			try {
				await logoutUser(token)
				console.log('User backend call to logout - success')
			} catch (err) {
				console.error('User backend call to logout - error', err)
			} finally {
				await logoutOkta()
			}
		}
	}

	return (
		<MainAppContext.Provider
			value={{
				userId,
				setUserId,
				userGroup,
				setUserGroup,
				isSessionAllowedToShowData,
				setSessionAllowedToShowData,
				isSessionVerifiedAsCurrent,
				setSessionVerifiedAsCurrent,
				loginCountGuide,
				setLoginCountGuide,
				loadingWhileExtendingSession,
				setLoadingWhileExtendingSession,
				lastGraphUpdateTime,
				setLastGraphUpdateTime,
				invalidateUser
			}}>
			{children}
		</MainAppContext.Provider>
	)
}

import React from 'react'
interface SidebarMainProps {
	height: number
}

export const SidebarMain = ({ height }: SidebarMainProps) => (
	<svg
		width="100%"
		height="auto"
		viewBox="0 0 288 auto"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		preserveAspectRatio="xMinYMin meet">
		<g
			style={{ mixBlendMode: 'overlay', transform: 'scaleX(-1)', transformOrigin: 'center' }}
			filter="url(#overlay_shadow_main)">
			<rect x="0" y="0" width="70" height="120%" fill="#D9D9D9" fillOpacity="0.01" />
		</g>
		<g filter="url(#right_border_main)">
			<line x1="286" y1="0" x2="286" y2="100%" stroke="url(#border_gradient_main)" strokeWidth="2" />
		</g>
		<defs>
			{/* <filter
				id="background_blur"
				x="-40"
				y="-100"
				width="368"
				height="150%"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
				<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="1" />
				<feGaussianBlur stdDeviation="1" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
				<feBlend mode="normal" in2="shape" result="inner_shadow" />
			</filter> */}

			{height > 886 && (
				<filter
					id="overlay_shadow_main"
					x="0"
					y="-100"
					width="169"
					height="150%"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="20" dy="0" /> {/* Move the shadow to the right */}
					<feGaussianBlur stdDeviation="10" /> {/* Keep a soft shadow on the right side */}
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.05 0 0 0 0 0.05 0 0 0 0 0.05 0 0 0 0.6 0" />
					<feBlend mode="normal" in2="shape" result="inner_shadow" />
				</filter>
			)}
			<filter
				id="right_border_main"
				x="266"
				y="-100"
				width="127.449"
				height="150%"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
				<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="1" />
				<feGaussianBlur stdDeviation="1" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
				<feBlend mode="normal" in2="shape" result="inner_shadow" />
			</filter>
			<linearGradient
				id="gradient_fill_main"
				x1="154.521"
				y1="215.12"
				x2="154.521"
				y2="100%"
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#03552D" stopOpacity="0" />
				<stop offset="0.625" stopColor="#000F08" stopOpacity="0.85" />
				<stop offset="1" stopColor="#000302" />
			</linearGradient>
			{height > 886 && (
				<linearGradient id="border_gradient_main" x1="284" y1="0" x2="284" y2="100%" gradientUnits="userSpaceOnUse">
					<stop stopColor="#B7DCDA" />
					<stop offset="0.10982" stopColor="#020202" />
					<stop offset="0.197086" stopColor="#23D27E" />
					<stop offset="0.241275" stopColor="#23D27E" />
					<stop offset="0.317125" stopColor="#B7DCDA" />
					<stop offset="0.465" stopColor="#131212" />
					<stop offset="0.532142" stopColor="#23D27E" stopOpacity="0.29" />
					<stop offset="0.715" stopColor="#020202" />
					<stop offset="0.825" stopColor="#383A3A" />
					<stop offset="0.87" stopColor="#B7DCDA" />
					<stop offset="0.955" stopColor="#020202" />
				</linearGradient>
			)}
		</defs>
	</svg>
)

import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import { getChildrenByType } from 'react-nanny'

import * as styles from './Tabs.css'
import { useTabsContext } from './TabsProvider'
import { Text } from 'components/typography/text'

interface Props {
	children: ReactNode
}

interface Tab {
	value: string
	children: ReactNode
}

interface Panel {
	value: string
	children: ReactNode
}

interface TabsComposition {
	Tab: FC<Tab>
	Panel: FC<Panel>
}

export const Tabs: FC<Props> & TabsComposition = ({ children }: Props) => {
	const tabsElements = getChildrenByType(children, [Tabs.Tab])
	const panelElements = getChildrenByType(children, [Tabs.Panel])

	return (
		<>
			{tabsElements.length > 0 && (
				<div className={styles.tabsWrapper} style={{ gridTemplateColumns: `repeat(${tabsElements.length}, 1fr)` }}>
					{tabsElements}
					<Slider />
				</div>
			)}
			{panelElements}
		</>
	)
}

Tabs.Tab = ({ value, children }: Tab) => {
	const { activeTab, onActiveTabChange, tabs } = useTabsContext()

	const tabIndices = generateTabIndices(tabs)
	const transform = tabIndices[value] * 100

	return (
		<button
			type="button"
			data-transform={transform}
			onClick={() => onActiveTabChange(value)}
			className={clsx(styles.tab, activeTab === value && styles.activeTab)}>
			<Text color="neutral.50" className={styles.text} variant={'metadata'}>
				{children}
			</Text>
		</button>
	)
}

Tabs.Panel = ({ value, children }: Panel) => {
	const { activeTab } = useTabsContext()
	return activeTab === value ? <>{children}</> : null
}

const Slider = () => {
	const { activeTab, tabs } = useTabsContext()
	const tabIndices = generateTabIndices(tabs)
	const transform = tabIndices[activeTab] * 100
	const sliderChildWidth = 100 / tabs.length

	return (
		<div className={styles.slider}>
			<div
				className={styles.sliderChild}
				style={{
					transform: `translateX(${transform}%)`,
					width: `${sliderChildWidth}%`
				}}
			/>
		</div>
	)
}

function generateTabIndices(tabValues: string[]): { [key: string]: number } {
	return tabValues.reduce((acc, value, index) => ({ ...acc, [value]: index }), {})
}

Tabs.Tab.displayName = 'Tabs.Tab'
Tabs.Panel.displayName = 'Tabs.Panel'

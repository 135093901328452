import React, { useState, useEffect } from 'react'

import { BlockIcon } from 'components/icons/block-icon'
import { IconButton } from 'components/inputs/icon-button'
import { Box } from 'components/layout/box'

import { ReactComponent as EyeIcon } from './assets/eye-icon.svg'
import { ReactComponent as SearchIcon } from './assets/search-icon.svg'
import { ReactComponent as SettingsIcon } from './assets/settings-icon.svg'
import { ReactComponent as LogoIcon } from './assets/logo.svg'
import { Text } from 'components/typography/text'

import { Link } from 'react-router-dom'

import * as styles from './Sidebar.css'
import { menuList } from './data'
import { NavItem, SidebarMain, SidebarMaster, SidebarNotch, TopReflection } from './components'
import { useMainAppContext } from 'context/MainAppContext'
import { redirectToCustomerPortal } from 'helpers/redirectToCustomerPortal'
import { useOktaAuth } from '@okta/okta-react'

export const DesktopSidebar = () => {
	const [openNav, setOpenNav] = useState<string | null>(null)
	const [viewportHeight, setViewportHeight] = useState<number>(window.innerHeight)
	const { userGroup, invalidateUser } = useMainAppContext()
	const { oktaAuth } = useOktaAuth()

	const logout = () => {
		invalidateUser()
	}

	const handleToggle = (navId: string) => {
		setOpenNav(prevNav => (prevNav === navId ? null : navId))
	}

	const handleCustomerPortalRedirect = () => {
		redirectToCustomerPortal(() => oktaAuth.getAccessToken())
	}

	useEffect(() => {
		const handleResize = () => {
			setViewportHeight(window.innerHeight)
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const filteredMenuList = menuList.filter(item => item.label !== 'More')

	return (
		<Box className={styles.desktopSidebar}>
			{/* MAIN */}
			<Box className={styles.mainReflection}>
				<SidebarMaster height={viewportHeight} />
			</Box>

			<Box className={styles.mainSidebar} paddingTop={6}>
				<Box className={styles.mainReflection}>
					<SidebarMain height={viewportHeight} />
				</Box>

				<Box className={styles.topReflection}>
					<TopReflection />
				</Box>

				{/* LOGO */}
				<Box paddingBottom={12} style={{ width: '148px', marginLeft: '24px' }}>
					<Link to="/">{LogoIcon && <LogoIcon width="100%" />}</Link>
				</Box>

				{/* MENU */}
				<Box className={styles.menu}>
					{filteredMenuList.map(item => (
						<NavItem {...item} key={item.label} isOpen={openNav === item.label} onToggle={handleToggle} />
					))}
				</Box>
			</Box>

			{/* FOOTER */}
			<Box className={styles.footerSidebar}>
				<Box className={styles.notch}>
					<SidebarNotch />
				</Box>
				<Box>
					<Text>{userGroup}</Text>
				</Box>
				<Box className={styles.videoWrapper}>{/* <VideoPlayer videoId={'928460417'} /> */}</Box>
				{/* ACTION BUTTONS */}
				{/*  */}

				<Box className={styles.actionButtons} paddingBottom={8}>
					<IconButton variant="action" onClick={handleCustomerPortalRedirect}>
						<BlockIcon icon={EyeIcon} size="medium" />
					</IconButton>
					<IconButton variant="action" onClick={() => invalidateUser()}>
						<BlockIcon icon={SearchIcon} size="medium" />
					</IconButton>
					<IconButton variant="action" onClick={() => logout()}>
						<BlockIcon icon={SettingsIcon} size="medium" />
					</IconButton>
				</Box>
			</Box>
		</Box>
	)
}

import React, { useEffect, useMemo } from 'react'

import { Box } from 'components/layout/box'
import * as styles from './Arena.css'

import { Card } from 'components/Card'
import { LatestTrades } from './LatestTrades/LatestTrades'
import { Leaderboard } from 'components/Leaderboard'
import { useTabsContext } from 'components/Tabs/TabsProvider'
import { useArenaContext } from 'context/ArenaContext'
import { FormProvider, useForm } from 'react-hook-form'
import { BotFilter } from '../components/BotFilters/BotFilter'
import { useOrientation } from '@uidotdev/usehooks'
import clsx from 'clsx'
import { useMainAppContext } from 'context/MainAppContext'
import { useLocation } from 'react-router-dom'

interface FormValues {
	selectedStrategies: string[]
	selectedTickers: string[]
	selectedBotIds: number[]
}

export const Arena = () => {
	const { onActiveTabChange } = useTabsContext()
	const { arenaBotsData, setArenaSelectedBot } = useArenaContext()
	const { userGroup } = useMainAppContext()
	const location = useLocation()

	const { type: orientationType } = useOrientation()

	// Extract unique strategies, tickers, and bots
	const strategies = useMemo(() => {
		const strategyMap = new Map<string, { label: string; value: string; isAvailable: boolean }>()
		arenaBotsData.forEach(bot => {
			if (bot.strategy) {
				// Here we ensure only one entry per strategy
				strategyMap.set(bot.strategy, {
					label: bot.strategy,
					value: bot.strategy,
					isAvailable: !!bot.trades && bot.trades.length > 0
				})
			}
		})
		return Array.from(strategyMap.values())
	}, [arenaBotsData])

	const tickers = useMemo(() => {
		const tickerMap = new Map<string, { label: string; value: string; isAvailable: boolean }>()
		arenaBotsData.forEach(bot => {
			if (bot.trades && bot.trades.length > 0) {
				bot.trades.forEach(ticker => {
					// Using a map ensures unique tickers with defined availability
					tickerMap.set(ticker, {
						label: ticker,
						value: ticker,
						isAvailable: !!bot.trades && bot.trades.length > 0
					})
				})
			}
		})
		return Array.from(tickerMap.values())
	}, [arenaBotsData])

	const botOptions = useMemo(() => {
		// Filter arenaBotsData that has bot.trades as empty array
		return arenaBotsData.map(bot => ({
			label: bot.bot_name,
			value: bot.bot_id,
			avatar: bot.avatar,
			isAvailable: bot.trades && bot.trades.length > 0
		}))
	}, [arenaBotsData])

	// Set up form methods
	const methods = useForm<FormValues>({
		defaultValues: {
			selectedStrategies: [],
			selectedTickers: [],
			selectedBotIds: []
		}
	})

	const { watch, reset } = methods
	const selectedStrategies = watch('selectedStrategies') || []
	const selectedTickers = watch('selectedTickers') || []
	const selectedBotIds = watch('selectedBotIds') || []

	useEffect(() => {
		if (strategies.length > 0 || tickers.length > 0 || botOptions.length > 0) {
			reset({
				selectedStrategies: strategies.map(s => s.value),
				selectedTickers: tickers.map(t => t.value),
				selectedBotIds: botOptions.map(option => option.value)
			})
		}
	}, [strategies, tickers, botOptions, reset])

	const filteredBots = useMemo(() => {
		return arenaBotsData.filter(bot => {
			const strategyMatch = selectedStrategies.includes(bot.strategy)
			const tickerMatch = bot.trades.length === 0 || bot.trades.some(ticker => selectedTickers.includes(ticker))
			const idMatch = selectedBotIds.includes(bot.bot_id)

			return strategyMatch && tickerMatch && idMatch
		})
	}, [arenaBotsData, selectedStrategies, selectedTickers, selectedBotIds])

	const handleAvatarClick = (botId: number) => {
		const arenaBot = arenaBotsData.find(bot => bot.bot_id === botId)
		if (!arenaBot) {
			return
		}
		setArenaSelectedBot(arenaBot)
		onActiveTabChange('Roster')
	}

	useEffect(() => {
		if (location.hash) {
			const element = document.querySelector(location.hash)

			if (element) {
				element.scrollIntoView({ behavior: 'smooth' })
			}
		}
	}, [location])

	return (
		// <Box className={styles.arenaContainer}>
		<Box
			className={clsx({
				[styles.portraitLayout]: orientationType === 'portrait-primary',
				[styles.layout]: orientationType !== 'portrait-primary'
			})}>
			<FormProvider {...methods}>
				<Card
					title="Latest Trades"
					headerChildren={
						<Box display="flex" gap={2}>
							<BotFilter<'selectedBotIds'> title="Bots" options={botOptions} field="selectedBotIds" />
							<BotFilter<'selectedStrategies'> title="Strategy" options={strategies} field="selectedStrategies" />
							<BotFilter<'selectedTickers'> title="Ticker" options={tickers} field="selectedTickers" hasDesktopSearch />
						</Box>
					}
					style={{ gridArea: 'latestTrades' }}>
					<LatestTrades trades={filteredBots} />
				</Card>
			</FormProvider>

			<div id="leaderboard">
				<Card style={{ gridArea: 'leaderboard' }} title="Leaderboard">
					<Leaderboard isTransparent handleClick={handleAvatarClick} />
				</Card>
			</div>
		</Box>
		// </Box>
	)
}

import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import * as dialogStyles from './Dialog.css'
import { Button } from 'components/inputs/button'
import { IconButton } from 'components/inputs/icon-button'
import { BlockIcon } from 'components/icons/block-icon'
import { CloseIcon } from 'assets/icons/icons'
import { Text } from 'components/typography/text'
import { Box } from 'components/layout/box'

interface Props {
	children: React.ReactNode
}

export const MainInfoMavbotsArenaPageDialog = ({ children }: Props) => {
	return (
		<Dialog.Root>
			<Dialog.Trigger asChild>{children}</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay className={dialogStyles.dialogOverlay} />

				<Dialog.Content className={dialogStyles.dialogContent}>
					{/* Header */}
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Dialog.Title>
							<Text variant="body1Bold">Mavbots Arena</Text>
						</Dialog.Title>
						<Dialog.Close asChild>
							<IconButton variant="tertiary" size="small">
								<BlockIcon icon={CloseIcon} size="medium" color="neutral.500" />
							</IconButton>
						</Dialog.Close>
					</Box>

					<Box display="flex" justifyContent="flex-end" gap={2}>
						<Dialog.Close asChild aria-label="Save View">
							<Button size="small">Cancel</Button>
						</Dialog.Close>
					</Box>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	)
}

import React, { useMemo, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Box } from 'components/layout/box'
import * as styles from './MeetBots.css'
import { Card } from 'components/Card'
import { MeetAvatar } from '../components/Avatar/Avatar'
import { useArenaContext } from 'context/ArenaContext'
import { BotFilter } from '../components/BotFilters/BotFilter'
import { Text } from 'components/typography/text'

interface FormValues {
	selectedStrategies: string[]
	selectedTickers: string[]
	selectedBotIds: number[]
}

interface MeetBotsProps {}

export const MeetBots = ({}: MeetBotsProps) => {
	const { arenaBotsData, arenaSelectedBot, setArenaSelectedBot } = useArenaContext()

	const strategies = useMemo(() => {
		const strategySet = new Set<string>()
		arenaBotsData.forEach(bot => {
			if (bot.strategy) {
				strategySet.add(bot.strategy)
			}
		})
		return Array.from(strategySet)
	}, [arenaBotsData])

	const tickers = useMemo(() => {
		const tickerSet = new Set<string>()
		arenaBotsData.forEach(bot => {
			if (bot.trades && bot.trades.length > 0) {
				bot.trades.forEach(trade => {
					tickerSet.add(trade)
				})
			}
		})
		return Array.from(tickerSet)
	}, [arenaBotsData])

	const botOptions = useMemo(() => {
		return arenaBotsData.map(bot => ({
			label: bot.bot_name,
			value: bot.bot_id,
			avatar: bot.avatar
		}))
	}, [arenaBotsData])

	// Set up form methods
	const methods = useForm<FormValues>({
		defaultValues: {
			selectedStrategies: [],
			selectedTickers: [],
			selectedBotIds: []
		}
	})
	const { watch, reset } = methods
	const selectedStrategies = watch('selectedStrategies') || []
	const selectedTickers = watch('selectedTickers') || []
	const selectedBotIds = watch('selectedBotIds') || []

	useEffect(() => {
		if (strategies.length > 0 || tickers.length > 0 || botOptions.length > 0) {
			reset({
				selectedStrategies: strategies,
				selectedTickers: tickers,
				selectedBotIds: botOptions.map(option => option.value)
			})
		}
	}, [strategies, tickers, botOptions, reset])

	const filteredBots = useMemo(() => {
		return arenaBotsData.filter(bot => {
			const strategyMatch = selectedStrategies.includes(bot.strategy)
			// const tickerMatch = bot.trades && bot.trades.some(trade => selectedTickers.includes(trade))
			const tickerMatch = bot.trades.length === 0 || bot.trades.some(ticker => selectedTickers.includes(ticker))
			const idMatch = selectedBotIds.includes(bot.bot_id)

			return strategyMatch && tickerMatch && idMatch
		})
	}, [arenaBotsData, selectedStrategies, selectedTickers, selectedBotIds])

	return (
		<FormProvider {...methods}>
			<Box className={styles.meetBotsContainer}>
				<Card
					title="Meet the Bots"
					headerChildren={
						<Box display="flex" gap={2}>
							<BotFilter<'selectedBotIds'> title="Bots" options={botOptions} field="selectedBotIds" />
							<BotFilter<'selectedStrategies'>
								title="Strategy"
								options={strategies.map(strategy => ({ label: strategy, value: strategy }))}
								field="selectedStrategies"
							/>
							<BotFilter<'selectedTickers'>
								title="Ticker"
								options={tickers.map(ticker => ({ label: ticker, value: ticker }))}
								field="selectedTickers"
							/>
						</Box>
					}
					style={{ minHeight: 'auto' }}
					bodyStyle={{
						marginLeft: '-12px',
						marginRight: '-12px',
						paddingLeft: '24px',
						paddingRight: '24px'
					}}>
					<Box className={styles.botsWrapper}>
						{filteredBots && filteredBots.length > 0 ? (
							filteredBots.map(bot => (
								<MeetAvatar
									handleClick={() => setArenaSelectedBot(bot)}
									key={bot.bot_id}
									avatar={bot.avatar}
									botName={bot.bot_name}
									isSelected={arenaSelectedBot?.bot_id === bot.bot_id}
									isAvailable={bot.bot_details.cumulative_returns.length > 0}
								/>
							))
						) : (
							<Box display="flex" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
								<Text variant="body1" color="neutral.500" textAlign="center">
									No Results
								</Text>
							</Box>
						)}
					</Box>
				</Card>
			</Box>
		</FormProvider>
	)
}

import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'

export const Error = ({ errorMessage = 'Something went wrong' }: { errorMessage?: string }) => {
	return (
		<Box paddingTop={4}>
			<Text variant="metadata" textAlign="center" color="neutral.300">
				{errorMessage}
			</Text>
		</Box>
	)
}

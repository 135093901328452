import { ChatButton } from 'components/Chat'
import { Box } from 'components/layout/box'
import { tokens } from 'style/theme.css'

interface Props {
	expanded: boolean
	onToggle: () => void
}

export const PanelKnowledgeBot = ({ expanded, onToggle }: Props) => {
	return (
		<Box
			style={{
				// opacity: '0.3',
				marginRight: '24px',
				marginBottom: '24px',
				zIndex: 1
				// width: '80px',
				// height: '80px',
				// background: tokens.colors['primary.500']
			}}>
			<ChatButton />
		</Box>
	)
}

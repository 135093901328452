import { MarketIcon, MavBotsIcon, DecoderIcon, HerdIcon, NewsIcon, MenuDotsIcon } from 'assets/icons/icons'

export type SubNavItem = {
	to: string
	label: string
	isOpen?: boolean
}

export type MenuItem = {
	to?: string
	label: string
	mobileLabel: string
	icon?: any
	subNav: SubNavItem[]
}

export const menuList: MenuItem[] = [
	{
		to: '/mavbots-arena',
		label: 'MavBots Arena',
		mobileLabel: 'MavBots',
		icon: MavBotsIcon,
		subNav: []
	},
	{
		to: '/state-of-market',
		label: 'State of Market',
		mobileLabel: 'Market',
		icon: MarketIcon,
		subNav: []
	},
	{
		label: 'Decoder',
		mobileLabel: 'Decoder',
		icon: DecoderIcon,
		subNav: [
			{
				to: '/decoder/navigator',
				label: 'Navigator'
			},
			{
				to: '/decoder/nexus',
				label: 'Nexus'
			},
			{
				to: 'decoder/sifter',
				label: 'Sifter'
			}
		]
	},
	{
		label: 'Herd',
		mobileLabel: 'Herd',
		icon: HerdIcon,
		subNav: [
			{
				to: 'herd/trending',
				label: 'Trending'
			},
			{
				to: 'herd/herdpower',
				label: 'HerdPower®'
			},
			{
				to: 'herd/screener',
				label: 'Screener'
			}
		]
	},
	{
		to: '/news',
		icon: NewsIcon,
		label: 'News',
		mobileLabel: 'News',
		subNav: []
	},
	{
		to: '/hub',
		icon: NewsIcon,
		label: 'Hub',
		mobileLabel: 'IN',
		subNav: []
	},
	{
		label: 'More',
		icon: MenuDotsIcon,
		mobileLabel: 'More',
		subNav: [
			{ to: '/watchlist', label: 'Watchlist' },
			{ to: '/hub', label: 'Hub' }
		]
	}
]

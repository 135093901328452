import clsx from 'clsx'
import React from 'react'
import { NavLink, useMatch } from 'react-router-dom'

import { ArrowIcon } from 'assets/icons/icons'

import { Box } from 'components/layout/box'
import { Heading } from 'components/typography/heading'
import { tokens } from 'style/theme.css'

import * as styles from '../Sidebar.css'
import { MenuItem } from '../data'
import { SubNavItem } from './SubNavItem'

interface NavItemProps extends MenuItem {
	isOpen: boolean
	onToggle: (navId: string) => void
}

export const NavItem = ({ to, label, isOpen, subNav, onToggle }: NavItemProps) => {
	const match = useMatch(to || '')
	const isActive = to ? match !== null : false

	const toggleSubNav = (): void => {
		onToggle(label)
	}

	if (to) {
		return (
			<NavLink to={to} className={styles.navLink}>
				<Box
					className={clsx(styles.navItem, isActive && styles.navItemOpen)}
					paddingY={2.5}
					paddingLeft={8}
					paddingRight={6}>
					<Heading
						variant="h2"
						color={isActive ? 'primary.500' : 'neutral.50'}
						fontWeight={isActive ? 'medium' : 'normal'}>
						{label}
					</Heading>
				</Box>
			</NavLink>
		)
	}

	return (
		<>
			<Box
				tabIndex={0}
				className={clsx(styles.navItem, isOpen && styles.navItemOpen)}
				paddingY={2.5}
				paddingLeft={8}
				paddingRight={6}
				onClick={toggleSubNav}
				onKeyDown={event => {
					if (event.key === 'Enter') {
						toggleSubNav()
					}
				}}>
				<Heading variant="h2" color={isOpen ? 'primary.500' : 'neutral.50'}>
					{label}
				</Heading>
				<ArrowIcon color={isOpen ? tokens.colors['primary.500'] : tokens.colors['neutral.500']} isActive={isOpen} />
			</Box>
			<Box
				display="flex"
				flexDirection="column"
				// gap={2.5}
				style={{
					height: isOpen ? `${subNav.length * 48}px` : '0',
					overflow: 'hidden',
					transition: 'height 0.5s'
				}}>
				{subNav.length > 0 && subNav.map(subItem => <SubNavItem {...subItem} key={subItem.label} isOpen={isOpen} />)}
			</Box>
		</>
	)
}

import React, { useState, HTMLAttributes } from 'react'
import clsx from 'clsx'
import { ButtonHTMLAttributes } from 'react'

import { IconButtonVariants, iconButton, hover, leave, active } from './IconButton.css'

type Props = ButtonHTMLAttributes<HTMLButtonElement> &
	IconButtonVariants & {
		isActive?: boolean
	}

export const IconButton = ({
	variant = 'primary',
	size = 'large',
	className,
	children,
	isActive,
	...rest
}: Props) => {
	const [isHovered, setIsHovered] = useState(false)
	const [hasBeenHovered, setHasBeenHovered] = useState(false)

	const handleMouseEnter = () => {
		setIsHovered(true)
		setHasBeenHovered(true)
	}

	const handleMouseLeave = () => {
		setIsHovered(false)
	}
	return (
		<button
			type="button"
			className={clsx(
				iconButton({ variant, size }),
				// isHovered ? hover : hasBeenHovered ? leave : '',
				// isActive ? active : '',
				// isHovered ? hover : hasBeenHovered ? (isActive ? active : leave) : '',
				isHovered ? hover : hasBeenHovered ? (isActive ? active : leave) : isActive ? active : '',

				className
			)}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			{...rest}>
			{children}
		</button>
	)
}

export const GlassIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="20%"
			viewBox="0 0 1286 652"
			fill="none"
			preserveAspectRatio="meet">
			<path
				opacity="0.4"
				d="M0 652V48C0 21.4903 21.4903 0 48 0H1240C1266.51 0 1288.32 21.6251 1285.23 47.9535C1231.65 503.725 415.128 630.506 0 652Z"
				fill="url(#paint0_radial_4564_1534)"
			/>
			<defs>
				<radialGradient
					id="paint0_radial_4564_1534"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(137.357 49.1012) rotate(46.5807) scale(737.116 848.725)">
					<stop stopColor="#103213" />
					<stop offset="1" stopColor="#73CABA" stopOpacity="0" />
					<stop offset="1.0001" stopColor="#103213" stopOpacity="0" />
				</radialGradient>
			</defs>
		</svg>
	)
}

import React, { Dispatch, useState } from 'react'
import clsx from 'clsx'
import * as Popover from '@radix-ui/react-popover'
import './style.css'
import { Button } from 'components/inputs/button'
import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import * as styles from './Dropdown.css'
import { tokens } from 'style/theme.css'
import { ChevronDownIcon } from 'assets/icons/icons'
import { BlockIcon } from 'components/icons/block-icon'

type DataType = {
	label: string
	value: string
}

interface Props {
	data: DataType[]
	handleFilter: (val: DataType) => void
	filter: DataType
}

export const Dropdown = ({ data, filter, handleFilter }: Props) => {
	const [isOpen, setIsOpen] = useState(false)

	const handlePresetClick = (val: DataType) => {
		handleFilter(val)
		setIsOpen(false)
	}

	const handleIsOpen = () => {
		setIsOpen(!isOpen)
	}

	return (
		<Popover.Root open={isOpen} onOpenChange={handleIsOpen}>
			<Popover.Trigger asChild>
				<Button
					variant="primary"
					size="small"
					style={{ paddingLeft: tokens.spacing[2], paddingRight: tokens.spacing[2] }}>
					{filter.label}
					<BlockIcon icon={ChevronDownIcon} size="small" />
				</Button>
			</Popover.Trigger>
			<Popover.Portal>
				<Popover.Content className={clsx('PopoverContent', styles.content)} style={{ zIndex: 1 }} sideOffset={5}>
					<Box className={styles.dataListContainer}>
						{data.map(val => {
							return (
								<Box
									key={val.value}
									className={clsx(styles.dataListRow, filter === val && styles.dataListRowSelected)}
									tabIndex={0}
									onClick={() => handlePresetClick(val)}
									onKeyDown={e => {
										if (e.code === 'Enter') {
											handlePresetClick(val)
										}
									}}
									role="button"
									aria-pressed={filter === val}>
									<Text>{val.label}</Text>
								</Box>
							)
						})}
					</Box>
				</Popover.Content>
			</Popover.Portal>
		</Popover.Root>
	)
}

import React from 'react'
import { NavLink, useMatch } from 'react-router-dom'

import { Heading } from 'components/typography/heading'

import * as styles from '../Sidebar.css'
import { SubNavItem as SubNavItemType } from '../data'

export const SubNavItem = ({ to, label, isOpen }: SubNavItemType) => {
	const match = useMatch(to)
	const isActive = match !== null

	return (
		<NavLink className={styles.navLink} to={to} tabIndex={isOpen ? 0 : -1}>
			<Heading variant="h2" className={styles.subNavItem} color={isActive ? 'primary.500' : 'neutral.50'}>
				{label}
			</Heading>
		</NavLink>
	)
}

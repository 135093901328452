import React, { useEffect } from 'react'

import { Box } from 'components/layout/box'
import * as styles from './MavbotsArena.css'
import { MainTitle } from 'components/typography/main-title'
import { TabsProvider, useTabsContext } from 'components/Tabs/TabsProvider'
import { Tabs } from 'components/Tabs'

import { MainInfoMavbotsArenaPageDialog } from 'components/Dialog/MainInfoMavbotsArenaPageDialog'
import { Arena } from './Arena'
import { Roster } from './Roster'
import { fetchBotStats, fetchBotsVideos, fetchBotTrades, fetchQuote } from 'api/mavbots-arena'
import { useOktaAuth } from '@okta/okta-react'
import { useQuery } from '@tanstack/react-query'
import { useArenaContext } from 'context/ArenaContext'
import { fetchBotsImages } from 'api/mavbots-arena/fetchBotsImages'
import { useMainAppContext } from 'context/MainAppContext'
import { useLocation, useNavigate } from 'react-router-dom'

export const MavbotsArena = () => {
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()
	const { userGroup } = useMainAppContext()
	const { activeTab, onActiveTabChange } = useTabsContext()
	const { setArenaBotsData, setArenaSelectedBot, setArenaQuote, setAvatarImages, setAvatarVideos, setArenaBotsTrades } =
		useArenaContext()
	const location = useLocation()
	const navigate = useNavigate()

	const { data: arenaBotsData } = useQuery({
		queryKey: ['mavbots-arena', 'mavbots'],
		queryFn: () => fetchBotStats(accessToken)
	})

	const { data: arenaBotsTradesData } = useQuery({
		queryKey: ['mavbots-arena', 'mavbots', 'trades'],
		queryFn: () => fetchBotTrades(accessToken)
	})

	const { data: botImages } = useQuery({
		queryKey: ['bot-images', arenaBotsData?.map(bot => bot.avatar)],
		queryFn: () =>
			fetchBotsImages(
				accessToken,
				arenaBotsData?.map(bot => bot.avatar)
			),
		enabled: !!arenaBotsData
	})

	const { data: botVideos } = useQuery({
		queryKey: ['bot-videos', arenaBotsData?.map(bot => bot.videoAvatar)],
		queryFn: () =>
			fetchBotsVideos(
				accessToken,
				arenaBotsData?.map(bot => bot.videoAvatar)
			),
		enabled: !!arenaBotsData
	})

	const { data: quoteTextData } = useQuery({
		queryKey: ['arena-quote'],
		queryFn: () => fetchQuote(accessToken),
		enabled: process.env.REACT_APP_DABBLE !== userGroup
	})

	useEffect(() => {
		if (arenaBotsData && arenaBotsData.length > 0) {
			setArenaSelectedBot(arenaBotsData[0])
			setArenaBotsData(arenaBotsData)
		}
	}, [arenaBotsData])

	useEffect(() => {
		if (arenaBotsTradesData && arenaBotsTradesData.length > 0) {
			setArenaBotsTrades(arenaBotsTradesData)
		}
	}, [arenaBotsTradesData])

	useEffect(() => {
		if (quoteTextData) {
			setArenaQuote(quoteTextData.text)
		}
	}, [quoteTextData])

	useEffect(() => {
		if (botImages) {
			setAvatarImages(botImages)
		}
	}, [botImages, setAvatarImages])

	useEffect(() => {
		if (botVideos) {
			setAvatarVideos(botVideos)
		}
	}, [botVideos, setAvatarVideos])

	useEffect(() => {
		if (location.hash) {
			onActiveTabChange('Arena')
		}
	}, [location.hash, onActiveTabChange])

	// When the active tab is 'Arena' and there's a hash, scroll to the element
	useEffect(() => {
		if (activeTab === 'Arena' && location.hash) {
			const element = document.querySelector(location.hash)
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' })

				// Use IntersectionObserver to detect when the element is in view
				const observer = new IntersectionObserver(
					(entries, observer) => {
						if (entries[0].isIntersecting) {
							// Element is in view, remove the hash from the URL
							navigate(location.pathname, { replace: true })
							// Disconnect the observer once done
							observer.disconnect()
						}
					},
					{
						root: null,
						threshold: 1.0
					}
				)

				observer.observe(element)
			}
		}
	}, [activeTab, location.hash])

	return (
		<Box className={styles.mavbotsArenaContainer}>
			<Box className={styles.header}>
				<MainTitle Dialog={children => <MainInfoMavbotsArenaPageDialog>{children}</MainInfoMavbotsArenaPageDialog>}>
					Mavbots Arena
				</MainTitle>
			</Box>
			<Tabs>
				<Tabs.Tab value="Arena">Arena</Tabs.Tab>
				<Tabs.Tab value="Roster">Roster</Tabs.Tab>

				<Tabs.Panel value="Arena">
					<Arena />
				</Tabs.Panel>
				<Tabs.Panel value="Roster">
					<Roster />
				</Tabs.Panel>
			</Tabs>
		</Box>
	)
}

export const TopReflection = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto" viewBox="0 0 779 128" fill="none">
		<g style={{ mixBlendMode: 'color-dodge' }} opacity="0.2" filter="url(#filter0_b_4454_15645)">
			<path
				d="M157.82 0H777.713C778.424 0 779 0.874935 779 1.58587C779 2.27107 778.806 2.51984 778.121 2.55507C752.692 3.86415 182.966 33.1294 72.2111 33.1294C12.3046 33.1294 4.50604 80.3137 -6.10352e-05 128V48.93V0H157.82Z"
				fill="url(#paint0_radial_4454_15645)"
			/>
		</g>
		<defs>
			<filter
				id="filter0_b_4454_15645"
				x="-2"
				y="-2"
				width="783"
				height="132"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
				<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4454_15645" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4454_15645" result="shape" />
			</filter>
			<radialGradient
				id="paint0_radial_4454_15645"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(0.000397612 -2.15418e-05) scale(769.483 120.471)">
				<stop offset="0.0001" stopColor="#D9D9D9" />
				<stop offset="0.0002" stopColor="#D9D9D9" />
				<stop offset="0.935" stopColor="#D9D9D9" stopOpacity="0" />
				<stop offset="1" stopColor="#010B08" stopOpacity="0" />
			</radialGradient>
		</defs>
	</svg>
)

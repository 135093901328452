import axios from 'axios'

export interface TradeHistory {
	id: number
	datetime: string
	size: string
	message: string
}

export interface TradeHistory {
	bot_id: number
	trade_history: TradeHistory[] | []
}

const axiosClient = axios.create()

axiosClient.defaults.headers.common = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Credentials': true,
	'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
}

export const fetchBotTrades = async (accessToken: string | undefined): Promise<TradeHistory[]> => {
	const response = await axiosClient.get(`${process.env.REACT_APP_API_SERVER}/storage/arena/trades`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})

	return response.data
}

import React from 'react'
import { Box } from 'components/layout/box'
import * as styles from './Card.css'
import { Text } from 'components/typography/text'
import { IconButton } from 'components/inputs/icon-button'
import { BlockIcon } from 'components/icons/block-icon'
import { InfoIcon } from 'assets/icons/icons'
import { Tooltip } from 'components/Tooltip/Tooltip'

type ContentChild = {
	text: string
}

export interface CardHeaderProps {
	title?: string
	tooltip?: string | ContentChild[]
	children?: React.ReactNode
}

export const CardHeader = ({ title, tooltip, children }: CardHeaderProps) => {
	return (
		<Box className={styles.header}>
			<Box className={styles.headerContent}>
				<Text variant="body1Bold">{title ?? ''}</Text>
				{tooltip && (
					<Tooltip content={tooltip}>
						<div style={{ display: 'inline-block' }}>
							<IconButton variant="tertiary" size="xSmall">
								<BlockIcon icon={InfoIcon} size="medium" />
							</IconButton>
						</div>
					</Tooltip>
				)}
				{children && <Box>{children}</Box>}
			</Box>
			<div className={styles.lineWrapper}>
				<div className={styles.line1}></div>
				<div className={styles.line2}></div>
			</div>
		</Box>
	)
}

import { CumulativeReturns } from 'api/mavbots-arena/fetchBotStats'
import dayjs from 'dayjs'
import { Time } from 'lightweight-charts'

type Line = {
	time: Time
	value: number
}

export type LineChartDataType = {
	line1: Line[]
	line2: Line[]
}

export function prepareChartData(data: CumulativeReturns[]): { areaChartData: Line[]; lineChartData: Line[] } {
	let result = data.reduce(
		(acc, item) => {
			const time = dayjs(item.time).format('YYYY-MM-DD') // Format as 'YYYY-MM-DD' string
			acc.areaChartData.push({ time, value: item.algo_perf })
			acc.lineChartData.push({ time, value: item.benchmark_perf })
			return acc
		},
		{ areaChartData: [] as Line[], lineChartData: [] as Line[] }
	)

	// Sort by time
	result.areaChartData.sort((a, b) => (a.time > b.time ? 1 : -1))
	result.lineChartData.sort((a, b) => (a.time > b.time ? 1 : -1))

	// Deduplicate
	result.areaChartData = result.areaChartData.filter(
		(item, index, array) => index === 0 || item.time !== array[index - 1].time
	)
	result.lineChartData = result.lineChartData.filter(
		(item, index, array) => index === 0 || item.time !== array[index - 1].time
	)

	return result
}

export function prepareLeaderboardChartData(data: Line[]): Line[] {
	// Sort the data by time
	const sortedData = data.slice().sort((a, b) => (a.time > b.time ? 1 : -1))

	// Remove duplicate entries based on the time property
	const deduplicatedData = sortedData.filter((item, index, array) => index === 0 || item.time !== array[index - 1].time)

	return deduplicatedData
}
import { ReactNode } from 'react'

import { Box } from 'components/layout/box'

import * as styles from './MainLayout.css'

type Props = { children: ReactNode }

export const MainLayout = ({ children }: Props) => {
	return (
		<Box
			className={styles.mainLayout}
			style={{ backgroundImage: 'url(/background.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
			{children}
		</Box>
	)
}

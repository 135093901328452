import "src/style/theme.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/MavbotsArena/Arena/LatestTrades/BotCard/BotCard.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/MavbotsArena/Arena/LatestTrades/BotCard/BotCard.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VW227iMBB971dYlVYCaY0SCpSmL/sb++jETjAYO7IdAl3133dsB5OUUFpppZUgF3t8fOZyxpm1erconxL05wGhmmlTs8LyA8tQmiRJfXyF4ZZTu/EDP9wr5aYW5JShUjA/v22M5eUJF0paJm2GCrgy/frw/jAL8GmAJ5RyWQHSKgDnSlOmsSaUNyZD8+Q8fMRmQ6hqM4QX9TFc1vAHA6SrnEzmy+VPdLkks2Q1/Ymc2ZVpAtPhN5svpyP+5KTYVVo1koIHQukMHYieYGxejrtdbaYDnyvNqRtwd2zZHkYtw0QzAg48lhpC8Hg9r1UL02mp/RSpM5S4J6uJNNxyJbPwXCq9B55LgwCPRRM3jI09CUhLrZlh+sDwk+dRqzOAZrAVZK4X9rkPe4QAG2WBzu9Juk4oq6Y90ydv6lk7ZyC5zpVzeEpSMHzghudccAth2HBKmRwJ5obxamMv73ddjAwWnsEb5pKyIxTD6zj1jvgY1wi19FDjfC8BI7lRorEhzOqcE8FK6x8j2upuFEc04e6Ycu3U5DaDymr2clQtpnbRzZltWaCoDkyXQrUY8EhjVY/Ls+fiwaFkQR9pf0Hfzy8zitjrT7EvZKKQsQ65XjvZRpgXDyO4saFksT3VULdSSdZbm6GVE+kXtBV4mrC701CkQQSvJOZgaS49p9NX12EsO1rcajcilbv3iBJPdETyYhrjZTaay92wHvLROI3gbMdLw/NbDGJW3KKSpn2R0ltm84EZCwShvnDbyTFXgvYsyk/3Q2hPdMUlDlr4UIKVX/vdpEXHB7R5n/ZmHPisl/E8X8FdWlDJ7VlkvV34DfpXYL0uc+3WpQwj8HYIfL8RXG9ZXgovVlbE330r7o7gzbCXXZKPuOve83V39HaJ9+3wg39i3L+bh/9NfX44MWJD8M2gaLRxRVkrfrbvOOXKWgXdF68DVV/fhr/5T5UwNFLQu2m/YXm/0vlAeyLbuB53SxHbfonu/1cM/pWz+284K0Mb4QI4QAcRjZ6kMwi+t/m1Z5QTZAoNhxYikqLJHpLU+fW8cnZ+/eALMDp+buHvd6FSX5sDrKTDinnIhSp2Ae4LG/4FxeC1NPMKAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var avatarWrapper = 'wrk4f3h';
export var blurValue = 'wrk4f3n';
export var boldSymbol = 'wrk4f3e';
export var card = 'wrk4f31';
export var cardBack = 'wrk4f36';
export var cardFront = 'wrk4f34';
export var cardSide = 'wrk4f33';
export var cardWrapper = 'wrk4f30';
export var dateTime = 'wrk4f3a';
export var detailsGrid = 'wrk4f3k';
export var flipButton = 'wrk4f3m';
export var flippedCard = 'wrk4f32';
export var greenMessage = 'wrk4f3c';
export var headerWrapper = 'wrk4f3i';
export var hiddenBack = 'wrk4f35';
export var infoGrid = 'wrk4f3j';
export var listItem = 'wrk4f39';
export var mainCardGrid = 'wrk4f3g';
export var message = 'wrk4f3b';
export var redMessage = 'wrk4f3d';
export var showMoreButton = 'wrk4f3l';
export var size = 'wrk4f3f';
export var tradeHistoryContainer = 'wrk4f37';
export var tradeHistoryList = 'wrk4f38';
export const TopReflectionIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="128" viewBox="0 0 100% 128" fill="none">
			<g opacity="0.6" filter="url(#filter0_b_4564_820)">
				<path
					d="M222.755 0H1238.71C1239.42 0 1240 0.877792 1240 1.59091C1240 2.27108 1239.6 2.52653 1238.92 2.5704C1213.95 4.18414 758.946 33 102 33C17.5 33 6.5 80 0.144043 127.5V48.7389V48C0.144043 21.4903 21.6344 0 48.144 0H222.755Z"
					fill="url(#paint0_radial_4564_820)"
				/>
			</g>
			<defs>
				<filter
					id="filter0_b_4564_820"
					x="-1.85596"
					y="-2"
					width="1243.86"
					height="131.5"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
					<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4564_820" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4564_820" result="shape" />
				</filter>
				<radialGradient
					id="paint0_radial_4564_820"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(-14.4464 13.1896) scale(1238.31 82.1706)">
					<stop offset="0.0001" stopColor="#D9D9D9" />
					<stop offset="0.0002" stopColor="#67D586" />
					<stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
				</radialGradient>
			</defs>
		</svg>
	)
}

export const breakpointNames = ['mobile', 'tablet', 'desktop']



export const breakpoints = {
	mobile: '0',
	tablet: '768',
	desktop: '1280',
	xlDesktop: '1640',
	ultra: '2560'
}

export type Breakpoint = keyof typeof breakpoints

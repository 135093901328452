import { Box } from 'components/layout/box'
import * as tableStyles from './TableInfoBox.css'

type Props = {
	children: React.ReactNode
}

export const TableInfoBox = ({ children }: Props) => (
	<Box as="tr" className={tableStyles.infoTableBox}>
		<td colSpan={10}>{children}</td>
	</Box>
)

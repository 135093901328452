import React from 'react'

import * as styles from './Badge.css'
import { Badge as BadgeItemProps } from 'components/MavbotsArena/data/data'
import { Tooltip } from 'components/Tooltip/Tooltip'

interface BadgeProps {
	badge: BadgeItemProps
}

export const Badge = ({ badge }: BadgeProps) => {
	return (
		<Tooltip content={badge.name}>
			<div className={styles.badge}></div>
		</Tooltip>
	)
}

import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Box } from 'components/layout/box'
import * as styles from './Calendar.css'
import { Text } from 'components/typography/text'
import { TabsProvider } from 'components/Tabs/TabsProvider'
import { Tabs } from 'components/Tabs'
import { Dropdown } from 'components/Dropdown'
import { fetchEconomic } from 'api/right-side-panel'
import { Economic } from './Economic'
import { Earnings } from './Earnings'

const dropdownData = [
	{
		label: 'Today',
		value: 'today'
	},
	{
		label: 'This Week',
		value: 'this_week'
	},
	{
		label: 'Next Week',
		value: 'next_week'
	},
	{
		label: 'Next Month',
		value: 'next_month'
	}
]

interface Props {}

export const Calendar = ({}: Props) => {
	const [dateFilter, setDateFilter] = useState(dropdownData[0])

	return (
		<Box className={styles.mainContainer}>
			<Text variant="body1Bold">Calendar</Text>
			<TabsProvider defaultTab="Economic" tabs={['Economic', 'Earnings']}>
				<Box className={styles.header}>
					<Tabs>
						<Tabs.Tab value="Economic">Economic</Tabs.Tab>
						<Tabs.Tab value="Earnings">Earnings</Tabs.Tab>
					</Tabs>
					<Box>
						<Dropdown data={dropdownData} handleFilter={setDateFilter} filter={dateFilter} />
					</Box>
				</Box>

				<Tabs>
					{/* Tab: Economic */}
					<Tabs.Panel value="Economic">
						<Economic filter={dateFilter} />
						{/* Tab: Earning */}
					</Tabs.Panel>
					<Tabs.Panel value="Earnings">
						<Earnings filter={dateFilter} />
					</Tabs.Panel>
				</Tabs>
			</TabsProvider>
		</Box>
	)
}

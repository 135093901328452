import "src/style/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/RightSidePanel/PanelTop/PanelTop.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/RightSidePanel/PanelTop/PanelTop.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA9VW3Y7bKBS+z1OgjiLFUhgZO7Yzzk13d/Y5KmywQ4PBxSST2dW8ewHbGUgyVaWutKqsOMDh/H3nO+DHL6htipfmJQb/LgDo5cA0k6IEinKs2YnuzOo/kAlCzyVAu8Xb4nFWQVcquBokP2qn8sKI3huFOF7aaccEfF+K+7Nd3FPW7nUJ0vi0t3Mt+xLEdqRGgRv3mBAm2hKcsFpBODydD4c+OURWVklFqIIKE3YczH73pMlovsL1oVXyKEio+y0KcoLI+VZYDI1UXQmGGnO6iqNgGUoTEzNJmiDH8KyYmYTxmPw4pCFAZUWNMnU41VJoKkxWnz7tPoTtHQJOG30HjUpqLbvLJEifiT1VTHuSWnKpyjGLHivj3hMO+pVTk67kjHjLU5kskqg/2981mCYTVsPWujUWV4uHJLMPiJdrM06fk+JvU2VvkviT1E3+LJ7/ev4DbNwEpShBCcj8bfko2RT5pgBbN4kT+zhOLaI53Eq6+OALrQ5Mww4PhxJwJihW7yE+NE1jM4pmNlm19cfbHGWNJQNg5wplcKLnmh8JveGOV+/EFfqGSigKdqXvu2YGcA7ix3wAFA/U2A62bz7Ynkzb5VEH+7MfdKWj19wePpMmik2iiQIZCvt0M/UtYUPP8WsJGk7dwtfjoFnzCi8Ur82bKivCnLUCGgC7wV++TScddq5JHGH9du1cNeqjGqykl2y2MQ0hPRmzg7MTAlHu5YkqB4dP3+uqozQjtF0D1VZ4heJ0DRJkXijdrC1jDPXAQ148Z9vcUS/6IE50VebJfYkbPQXxUyfAvWaHCAWFQUVYmFl8aQmrBu0JcjkbsvEc9nG4m+/j05gfZz3ssXXWS/7aSrFKlyBeOwQu//a1Bk/5PCqKJciRHSRLkBVmYF4gTZchMvkvMDTLAiQ2xX2K/gCJdPNbsTj/f1lcOMeyxzXTBiuT4Xidn2HFqSCwk4SWoyFIJGnpzDKiZA8bxk1uJaj4Ua0MSaMAeCFFeF9unbMb+eeOEobNYaooFQALAlb+90SyNUWPnOpV2J6tisv6sHNr94nn373B7XvzLXPz7fDme97+l579w+CqH94Wb98BINuJ3LwJAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var contentBox = '_1gf7wfw1';
export var contentBoxClosing = '_1gf7wfw4';
export var contentBoxExpanded = '_1gf7wfw2';
export var contentBoxOpening = '_1gf7wfw3';
export var leftIconWrapper = '_1gf7wfw6';
export var panelTop = '_1gf7wfw0';
export var rightIconWrapper = '_1gf7wfw5';
export var topBackgroundReflection = '_1gf7wfw8';
export var topReflection = '_1gf7wfw7';
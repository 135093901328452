import React from 'react'

import { Box } from 'components/layout/box'
import * as styles from './BotProfileWidgets.css'
import { Trader } from 'components/MavbotsArena/data/data'
import {
	WidgetCardRiskRating,
	WidgetCardTotalReturn,
	WidgetCardValue,
	WidgetCardRiskAdjustedReturn
} from './WidgetCard'

interface BotProfileWidgetsProps {}

export const BotProfileWidgets = ({}: BotProfileWidgetsProps) => {
	return (
		<Box display="flex" gap={10}>
			<WidgetCardValue />
			<WidgetCardTotalReturn />
			<WidgetCardRiskAdjustedReturn />
			<WidgetCardRiskRating />
		</Box>
	)
}

import axios from 'axios'

const axiosClient = axios.create()

axiosClient.defaults.headers.common = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Credentials': true,
	'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
}

export const fetchBotsVideos = async (
	accessToken: string | undefined,
	videoAvatars?: string[]
): Promise<Record<string, string>> => {
	if (!videoAvatars) {
		return {}
	}

	// Fetch video URLs from the server
	const videoFetches = videoAvatars.map(async videoAvatar => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_SERVER}/storage/fetch-video-url/${videoAvatar}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			})

			if (response.ok) {
				const data = await response.json()
				const videoUrl = data.url // Extract video URL from the response
				return { [videoAvatar]: videoUrl } // Return the video URL with the bot's file name as the key
			} else {
				console.error('Error fetching video:', response.statusText)
				return { [videoAvatar]: '' } // Return an empty string in case of failure
			}
		} catch (error) {
			console.error('Error fetching video:', error)
			return { [videoAvatar]: '' } // Return an empty string in case of failure
		}
	})

	// Wait for all video fetches to complete and merge them into one object
	const videoDataArray = await Promise.all(videoFetches)
	return Object.assign({}, ...videoDataArray) // Merge all video objects into one
}

import axios from 'axios'

const axiosClient = axios.create({
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
})

const endpoint = 'https://mavbots-calendar-d145c1e46b8c.herokuapp.com'
const api_key = 'MFN5xzE0sPVFZpdpyJ5m'

export type EconomicDataInterface = {
	date: string
	time: string
	country: string
	importance: number
	event: string
	description: string
	forecast: string
	actual: string
	previous: string
	unit: string
}

export const fetchEconomic = async (period: string): Promise<EconomicDataInterface[]> => {
	try {
		const response = await axiosClient.get(`${endpoint}/calendar/economic/?period=${period}&apikey=${api_key}`)

		if (response.status !== 200) {
			throw new Error(`Error: Received status code ${response.status}`)
		}

		const data: EconomicDataInterface[] = response.data

		// Optional: Validate the data structure
		if (!Array.isArray(data)) {
			throw new Error('Error: Response data is not an array')
		}

		return data
	} catch (error) {
		console.error('Failed to fetch economic data:', error)
		throw error
	}
}

export type EarningsDataInterface = {
	date: string
	time: string
	mcap: number
	ticker: string
	importance: number
	name: string
	revenue_est: string
	revenue: string
	revenue_surprise_percent: string
	eps_est: string
	eps: string
	eps_surprise_percent: string
}

export const fetchEarnings = async (period: string): Promise<EarningsDataInterface[]> => {
	try {
		const response = await axiosClient.get(`${endpoint}/calendar/earnings/?period=${period}&apikey=${api_key}`)

		if (response.status !== 200) {
			throw new Error(`Error: Received status code ${response.status}`)
		}

		const data: EarningsDataInterface[] = response.data

		// Optional: Validate the data structure
		if (!Array.isArray(data)) {
			throw new Error('Error: Response data is not an array')
		}

		return data
	} catch (error) {
		console.error('Failed to fetch earnings data:', error)
		throw error
	}
}

import { useState } from 'react'
import { Box } from 'components/layout/box'
import { PanelTop } from './PanelTop/PanelTop'
import { PanelApproach } from './PanelApproach/PanelApproach'
import { PanelKnowledgeBot } from './PanelKnowledgeBot/PanelKnowledgeBot'

import * as styles from './RightSidePanel.css'

export const RightSidePanel = () => {
	const [expandedPanel, setExpandedPanel] = useState<null | 'leaderboard' | 'calendar' | 'approach' | 'knowledgeBot'>(
		null
	)
	// Need mechanism to handle the state of the panel
	// PanelTop, PanelApproach, PanelKnowledgeBot can all be expanded or collapse. But every time you open one, the other one should close

	const togglePanel = (panel: null | 'leaderboard' | 'calendar' | 'approach' | 'knowledgeBot') => {
		if (expandedPanel === panel) {
			// If the panel being toggled is already open, close all panels
			setExpandedPanel(null)
		} else {
			// Open the toggled panel and close others
			setExpandedPanel(panel)
		}
	}

	return (
		<div>
			<div className={expandedPanel ? styles.overlay : styles.hidden} onClick={() => togglePanel(null)}></div>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				alignItems="flex-end"
				className={styles.panelWrapper}>
				<PanelTop expanded={expandedPanel === 'leaderboard' || expandedPanel === 'calendar'} expandedPanel={expandedPanel} onToggle={togglePanel} />
				<PanelApproach expanded={expandedPanel === 'approach'} onToggle={() => togglePanel('approach')} />
				<PanelKnowledgeBot expanded={expandedPanel === 'knowledgeBot'} onToggle={() => togglePanel('knowledgeBot')} />
			</Box>
		</div>
	)
}

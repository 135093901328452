import clsx from 'clsx'
import { InputHTMLAttributes, forwardRef } from 'react'

import { InputWrapper } from './InputWrapper'
import { endIconSpacing, input, inputHasError, startIconSpacing } from './InputWrapper.css'

interface CustomInputProps {
	hasError?: boolean
	endIcon?: JSX.Element
	startIcon?: JSX.Element
	inputRef?: React.RefObject<HTMLInputElement> | null
}

type Props = InputHTMLAttributes<HTMLInputElement> & CustomInputProps

export const Search = forwardRef<HTMLInputElement, Props>(
	({ hasError, startIcon, endIcon, inputRef, ...rest }, ref) => {
		const inputReference = inputRef || ref
		return (
			<InputWrapper startIcon={startIcon} endIcon={endIcon}>
				<input
					{...rest}
					ref={inputReference}
					className={clsx(input, hasError && inputHasError, endIcon && endIconSpacing, startIcon && startIconSpacing)}
				/>
			</InputWrapper>
		)
	}
)

Search.displayName = 'Search'

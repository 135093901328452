import { TradeHistory } from 'api/mavbots-arena/fetchBotTrades'
import { Trader } from 'api/mavbots-arena/fetchBotStats'
import React, { createContext, useContext, useState, ReactNode } from 'react'

// Define the context type
interface ArenaContextType {
	arenaBotsData: Trader[]
	setArenaBotsData: (bots: Trader[]) => void
	arenaBotsTrades: TradeHistory[]
	setArenaBotsTrades: (bots: TradeHistory[]) => void
	arenaSelectedBot: Trader | undefined
	setArenaSelectedBot: (bot: Trader) => void
	// Arena Quote
	arenaQuote: string | undefined
	setArenaQuote: (quote: string) => void
	// Avatar images
	avatarImages: Record<string, string> // Store base64 images with the file name as key
	setAvatarImages: (images: Record<string, string>) => void
	avatarVideos: Record<string, string>
	setAvatarVideos: (videos: Record<string, string>) => void
}

// Create the context with a default value of undefined
const ArenaContext = createContext<ArenaContextType | undefined>(undefined)

// Create a custom hook to use the ArenaContext
export const useArenaContext = () => {
	const context = useContext(ArenaContext)
	if (!context) {
		throw new Error('useArenaContext must be used within an ArenaProvider')
	}
	return context
}

// Create the provider component
interface ArenaProviderProps {
	children: ReactNode
}

export const ArenaProvider = ({ children }: ArenaProviderProps) => {
	const [arenaBotsData, setArenaBotsData] = useState<Trader[]>([])
	const [arenaBotsTrades, setArenaBotsTrades] = useState<TradeHistory[]>([])
	const [arenaSelectedBot, setArenaSelectedBot] = useState<Trader | undefined>(undefined)
	const [arenaQuote, setArenaQuote] = useState<string | undefined>(undefined)
	const [avatarImages, setAvatarImages] = useState<Record<string, string>>({})
	const [avatarVideos, setAvatarVideos] = useState<Record<string, string>>({})

	return (
		<ArenaContext.Provider
			value={{
				arenaBotsData,
				setArenaBotsData,
				arenaBotsTrades,
				setArenaBotsTrades,
				arenaSelectedBot,
				setArenaSelectedBot,
				arenaQuote,
				setArenaQuote,
				avatarImages,
				setAvatarImages,
				avatarVideos,
				setAvatarVideos
			}}>
			{children}
		</ArenaContext.Provider>
	)
}

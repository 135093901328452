import { createColumnHelper } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { Box } from 'components/layout/box'
import { LeaderboardDataInterface } from 'api/mavbots-arena'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import * as tableStyle from './Leaderboard.css'
import { tokens } from 'style/theme.css'
import { Text } from 'components/typography/text'
import { useArenaContext } from 'context/ArenaContext'
import { UpArrow } from 'assets/icons/icons'
import { LeaderboardChart } from './LeaderboardChart'
import * as styles from './Leaderboard.css'

dayjs.extend(customParseFormat)

const leaderboardColumnHelper = createColumnHelper<LeaderboardDataInterface>()

export const leaderboardColumnsData = [
	leaderboardColumnHelper.accessor(row => row.rank, {
		id: 'rank',
		header: 'Rank',
		cell: info => {
			const { avatarImages } = useArenaContext()
			const name = info.row.original.bot_name
			const img = info.row.original.bot_avatar
			const img2 = avatarImages[img]

			return (
				<Box display="flex" gap={1} style={{ cursor: 'pointer' }}>
					<Text variant="body2" color="neutral.50">
						{info.getValue()}
					</Text>
					<Box as="img" className={tableStyle.tableCellAvatar} alt={name} src={img2} />
					<Text variant="body2" color="neutral.50" className={styles.tableCellName}>
						{name}
					</Text>
				</Box>
			)
		}
	}),
	leaderboardColumnHelper.accessor(row => row.rank_change, {
		id: 'rank_change',
		header: 'Rank change (week)',

		cell: info => {
			const numericValue = Number(info.getValue())
			const isPositive = numericValue > 0
			return (
				<Box display="flex" alignItems="center" gap={1}>
					<Text variant="body2" color="neutral.50">
						<span style={{ minWidth: '14px', display: 'inline-block' }}>{info.getValue()}</span>
					</Text>
					{numericValue !== 0 ? (
						<UpArrow
							color={isPositive ? tokens.colors['primary.500'] : tokens.colors['warning.700']}
							isActive={isPositive}
						/>
					) : (
						<div className={tableStyle.tableCellCircle} />
					)}
				</Box>
			)
		}
	}),
	leaderboardColumnHelper.accessor(row => row.strategy, {
		id: 'strategy',
		header: 'Strategy',
		cell: info => {
			return info.getValue()
		}
	}),
	leaderboardColumnHelper.accessor(row => row.ytd_performance, {
		id: 'ytd_performance',
		header: 'YTD Perf %',
		cell: info => {
			return info.getValue()
		}
	}),

	leaderboardColumnHelper.accessor(row => row.chart_performance, {
		id: 'chart_performance',
		header: '',
		cell: info => {
			const data = info.getValue()
			return (
				<div>
					<LeaderboardChart data={data} />
				</div>
			)
		}
	})
]

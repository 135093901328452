import React from 'react'
import numeral from 'numeral'

import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import * as styles from './WidgetCard.css'
import { getColorForValue } from 'helpers/getColorForValue'
import { CircleChart } from './CircleChart'

const renderSubValue = (value: number | string) => {
	const color = getColorForValue(value)
	const numericValue = numeral(value).value()
	if (numericValue === null || isNaN(numericValue)) {
		return <span>-</span> // Handle invalid numbers
	}

	// Conditionally format based on decimal places and add "+" for positive values
	const formattedValue =
		numericValue >= 0
			? `+${Number.isInteger(numericValue) ? numeral(value).format('0,0') : numeral(value).format('0,0.00')}%`
			: `${numeral(value).format('0,0.00')}%`

	return <span style={{ color }}>{formattedValue}</span>
}

const Label = ({ children }: { children: React.ReactNode }) => (
	<Text variant="metadataBold" color="neutral.100">
		{children}
	</Text>
)

const MainValue = ({ children }: { children: React.ReactNode }) => (
	<Text variant="h2" fontWeight="bold" color="neutral.50">
		{children}
	</Text>
)
const SubValue = ({ children }: { children: React.ReactNode }) => (
	<Text variant="metadata" color="neutral.100">
		{children}
	</Text>
)

const DateLabel = ({ children }: { children: React.ReactNode }) => (
	<Text variant="metadataBold" color="neutral.300" style={{ marginTop: 'auto' }}>
		{children}
	</Text>
)

interface WidgetCardProps {}

export const WidgetCardValue = ({}: WidgetCardProps) => {
	return (
		<Box className={styles.widgetCard}>
			<Box className={styles.contentFlex}>
				<Label>Value</Label>
				<Box display="flex" flexDirection="column" gap={0.5}>
					<MainValue>$906,835.85</MainValue>
					<SubValue>$100,000 invested</SubValue>
				</Box>
				<DateLabel>Since 7 Jan 2014</DateLabel>
			</Box>
		</Box>
	)
}

export const WidgetCardTotalReturn = ({}: WidgetCardProps) => {
	return (
		<Box className={styles.widgetCard}>
			<Box className={styles.contentFlex}>
				<Label>Total Return</Label>
				<Box display="flex" flexDirection="column" gap={0.5}>
					<MainValue>+806.8%</MainValue>
					<SubValue>({renderSubValue(-201.7)} over SPY)</SubValue>
					{/* <CircleChart value={80} maxValue={100} label="Win Rate" /> */}
				</Box>
				<DateLabel>Since 7 Jan 2014</DateLabel>
			</Box>
		</Box>
	)
}

export const WidgetCardRiskAdjustedReturn = ({}: WidgetCardProps) => {
	return (
		<Box className={styles.widgetCard}>
			<Box className={styles.contentFlex}>
				<Label>Risk Adjusten Return</Label>
				<Box display="flex" flexDirection="column" gap={0.5}>
					<MainValue>2.1%</MainValue>
					<SubValue>({renderSubValue('204')} over SPY)</SubValue>
				</Box>
				<DateLabel>Since 7 Jan 2014</DateLabel>
			</Box>
		</Box>
	)
}

export const WidgetCardRiskRating = ({}: WidgetCardProps) => {
	return (
		<Box className={styles.widgetCard}>
			<Box className={styles.contentFlex}>
				<Label>Risk Rating</Label>
				<MainValue>Low</MainValue>
			</Box>
		</Box>
	)
}

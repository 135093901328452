import "src/style/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/MavbotsArena/components/Avatar/Avatar.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/MavbotsArena/components/Avatar/Avatar.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81XUY/iNhB+319hXXUSOWEUJ0BI9qUP9z8qJzbgI8SpY3bZq/a/d+yEYGcJ0N5xrZYViWc8/jz+5vMw+4PonMcJCdFfTwjVshFayCpDipdUixf+DKOvgulthlar+mhet1xstvr8nkvFuMKKMnFoMrQIP5tRJpq6pG8ZWpfcutFSbCosNN+DU8ErzZUZ/nZotFi/4ULCSKVdU3FQjVQZoBLt0PvT7ISXDPDSvJHlQbt4SdgiOeE9vee02G2UPFQMtgmgaYk35hvWnRRCFSVHcVgfEdVmL3baFP0Wka9p8hWZZ7XJ6SSc2r8ZCaxHMJ4JrWjVwyxLFM6SBnHacCwqLA/a21dk99XtoKBlMTHREUaQ7MDdzSXb5fXlC1frUr5maCsY45UZ+w5rM36EpDzbNNoEY/4COYCJlay4hyp2UY3lVebfeKHxWhh0ZlEvxNyGeBhXLjNBVA0Ht9A8r2ESbsR3nqEXqiYYN+lxt6vjMOitr92OPDu19kKWhoquhRBrkTUthH7rVnHJVYqKU3UmV8j4pmcP6j6zNHBJ1Q9HDq/80/rIp3icT/Nsa47CJr9HSjyXsHMZHNW93m0lWlBrqfZQU1JTzSeYrMyGA2/24rbMkHjp60w/8CDyOPCW/zsVdMAlY9JAlle0oTc+TBxWD5XixX8mxcsfYnp6B5XCAZXCX32h/kMxSR0xyeURN1vKDHXMEZn/ZfdtTyWOpygiyRSRaGEEbR48+9lrgKZ8Qmbhws8cHY1Pou6ho8BIwGgQ7wbqfxc1t/HWooREZghY+9Y5tgx0PIsr9eGWmjOF2Sk/48Q/XlvbwK88fMHnT6eGABvkBiAL1qalpgriXyDqvKVvTRkT1ca+o066ncu300vNjxo7aT7UNVcFEG+ATiqxEZC1bi04QHuxAx7aprN99CWJZTmHsNymsU/Lp083O4VWOQH3F4TJJSER1ZYrod30uEOOnsGyojjL2VrJPUqdFoAQKBCyTKFK4iUUSICi5WzxecQ6i8G+um6Pk+v2+WrMGhiRGbUlN3AlDq5h1UMbs7oBK70Cy1bT4OxvkgP6uHwnNN7TZvexC8tLmD9F9is4kcNMnV53NZHviHhHFBcfLkpRZz4Mfzf3efkx5d6yHKrtKNU5yHmYH4vywHwJwgMNcmRzvPu9JKhR6N4DJLSUMCcbz+2Rh74+8l/8U27kdnp4P5EO+wk+1k/MP7YT9N7J3dT7r7pH/Rz2bvQB7Met+fueQzi4tBXnFaIVQ5M9nMWJR5HpsQKLYdCp9VxLovaCOrPtNPL+9P43LHM81jARAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var avatar = '_1tbe3710';
export var avatarBackground = '_1tbe3711';
export var conquerButton = '_1tbe371d';
export var grayscale = '_1tbe371b';
export var img = '_1tbe3713';
export var imgWrapper = '_1tbe3712';
export var meetAvatar = '_1tbe3719';
export var meetAvatarBackground = '_1tbe371e';
export var meetAvatarDisabledButtonWrapper = '_1tbe371c';
export var meetAvatarSelected = '_1tbe371a';
export var profileAvatar = '_1tbe3715';
export var profileAvatarBackground = '_1tbe3718';
export var profileAvatarSmall = '_1tbe3716';
export var profileWrapper = '_1tbe3717';
export var seeStatsLayer = '_1tbe3714';
import { ComponentType, SVGProps } from 'react'
import clsx from 'clsx'

import { Atoms, atoms } from 'style/atoms.css'

interface Props extends SVGProps<SVGSVGElement> {
	icon: ComponentType<SVGProps<SVGSVGElement>>
	color?: Atoms['color']
}

export const InlineIcon = ({ icon: Icon, color, className, ...rest }: Props) => {
	return <Icon width="1em" height="1em" className={clsx(atoms({ color }), className)} {...rest} />
}

export const SidebarNotch = () => (
	<svg width="288" height="345" viewBox="0 0 288 345" fill="none" xmlns="http://www.w3.org/2000/svg">
		{/* <g filter="url(#background_blur_notch)">
			<path
				d="M0 -679H288V-10.4577C288 3.38544 282.873 16.7379 273.607 27.0232L256.943 45.5229C247.677 55.8082 242.55 69.1607 242.55 83.0038V168.117C242.55 180.52 246.668 192.573 254.257 202.383L276.293 230.867C283.882 240.677 288 252.73 288 265.133V345H0V-679Z"
				fill="#21242B"
				fillOpacity="0.45"
			/>
		</g> */}
		<g style={{ mixBlendMode: 'overlay' }} filter="url(#overlay_shadow_notch)">
			<path
				d="M221 -679.617V-639.847V-13.2307C221 -8.43933 219.566 -3.75769 216.882 0.211609L179.118 56.0689C176.434 60.0382 175 64.7198 175 69.5112V211.499L221.75 266.018V352.999H286V265.4C286 253.459 282.183 241.83 275.106 232.212L250.894 199.305C243.817 189.687 240 178.058 240 166.117V83.0607C240 69.5806 244.862 56.5529 253.695 46.3694L272.305 24.9111C281.138 14.7276 286 1.69989 286 -11.7802V-679.617H221Z"
				fill="#D9D9D9"
				fillOpacity="0.01"
			/>
		</g>
		<path
			opacity="1"
			d="M-1 211C-1 207.686 1.68629 205 5 205H230C260.928 205 286 230.072 286 261V345H-1V211Z"
			fill="url(#gradient_fill_notch)"
		/>
		<g filter="url(#border_gradient_group_notch)">
			<path
				d="M286.45 -679V-10.4577C286.45 3.38542 281.323 16.7379 272.057 27.0232L255.393 45.5229C246.127 55.8082 241 69.1607 241 83.0038V168.117C241 180.52 245.118 192.573 252.707 202.383L274.743 230.867C282.332 240.677 286.45 252.73 286.45 265.133V345"
				stroke="url(#border_gradient_notch)"
				strokeWidth="2"
			/>
		</g>
		<defs>
			<filter
				id="overlay_shadow_notch"
				x="159"
				y="-679.617"
				width="127"
				height="1032.62"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dx="-16" />
				<feGaussianBlur stdDeviation="10" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.05 0 0 0 0 0.05 0 0 0 0 0.05 0 0 0 0.6 0" />
				<feBlend mode="normal" in2="shape" result="effect1_innerShadow_1632_5957" />
			</filter>
			<filter
				id="border_gradient_group_notch"
				x="200"
				y="-719"
				width="127.449"
				height="1104"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
				<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1632_5957" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1632_5957" result="shape" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="1" />
				<feGaussianBlur stdDeviation="1" />
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
				<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
				<feBlend mode="normal" in2="shape" result="effect2_innerShadow_1632_5957" />
			</filter>
			<linearGradient
				id="gradient_fill_notch"
				x1="154.521"
				y1="215.12"
				x2="154.521"
				y2="345"
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#03552D" stopOpacity="0" />
				<stop offset="0.625" stopColor="#000F08" stopOpacity="0.85" />
				<stop offset="1" stopColor="#000302" />
			</linearGradient>
			<linearGradient id="border_gradient_notch" x1="142.45" y1="0" x2="142.45" y2="345" gradientUnits="userSpaceOnUse">
				<stop stopColor="#B7DCDA" />
				<stop offset="0" stopColor="#020202" />
				<stop offset="0.197086" stopColor="#23D27E" />
				<stop offset="0.241275" stopColor="#23D27E" />
				<stop offset="0.317125" stopColor="#B7DCDA" />
				<stop offset="0.365" stopColor="#131212" />
				<stop offset="0.432" stopColor="#23D27E" stopOpacity="0.29" />
				<stop offset="0.515" stopColor="#020202" />
				<stop offset="0.67" stopColor="#B7DCDA" />
				<stop offset="0.695" stopColor="#383A3A" />
				<stop offset="0.955" stopColor="#020202" />
			</linearGradient>
		</defs>
	</svg>
)

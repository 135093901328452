import { toRelativeUrl } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect } from 'react'

import { Sidebar } from 'components/Header/Sidebar'
import { Outlet } from 'components/layout/outlet'

import { MainLayout } from 'components/layout/main-layout'
import { RightSidePanel } from 'components/RightSidePanel'

const RequiredAuth = ({
	isAllowed,
	onAuthRequired
}: {
	isAllowed: boolean
	onAuthRequired: (oktaAuth: any) => void
}) => {
	const { oktaAuth, authState } = useOktaAuth()

	if (!authState || authState.isAuthenticated === undefined) {
		// While checking auth state, render a loading indicator
		return <div>Loading...</div>
	}

	if (!authState.isAuthenticated) {
		const originalUri = toRelativeUrl(window.location.href, window.location.origin)
		oktaAuth.setOriginalUri(originalUri)

		console.log('ON AUTH REQUIRED', onAuthRequired)

		if (onAuthRequired) {
			console.log('IN ON AUTH REQUIRED')
			onAuthRequired(oktaAuth)
		} else {
			console.log('SIGN IN WITH REDIRECT')
			oktaAuth.signInWithRedirect()
		}
		return null
	}

	if (!isAllowed) {
		return <div>Checking user status</div>
	}

	return (
		<MainLayout>
			<Sidebar />
			<Outlet />
			{/* <Avatar /> */}
			<RightSidePanel />
		</MainLayout>
	)
}

export default RequiredAuth

import React from 'react'

import { Box } from 'components/layout/box'
import * as styles from './BotProfile.css'
import { Card } from 'components/Card'
import { ProfileAvatar } from 'components/MavbotsArena/components/Avatar/Avatar'
import { Text } from 'components/typography/text'
import { Badge } from 'components/MavbotsArena/components/Badge/Badge'
import { Trader } from 'components/MavbotsArena/data/data'
import { Button } from 'components/inputs/button'
import { RiskChip } from 'components/MavbotsArena/components/RiskChip/RiskChip'
import { Stack } from 'components/layout/stack'
import { TradesList } from 'components/MavbotsArena/components/TradesList/TradesList'
import { BotProfileTabs } from './BotProfileTabs/BotProfileTabs'
import { BotProfileChart } from './BotProfileChart/BotProfileChart'
import { useArenaContext } from 'context/ArenaContext'
import { BotProfileWidgets } from './BotProfileWidgets/BotProfileWidgets'

interface BotProfileProps {
	data: Trader
}

export const BotProfile = ({ data }: BotProfileProps) => {
	const { arenaSelectedBot } = useArenaContext()

	return (
		<Box className={styles.botProfileContainer}>
			<Card style={{ width: '100%' }}>
				{!arenaSelectedBot ? (
					<Text variant="body2" textAlign="center" color="neutral.300">
						Loading...
					</Text>
				) : (
					<Box display="flex" flexDirection="column" gap={4}>
						<Box className={styles.botProfileOverview}>
							<Box display="flex" gap={{ mobile: 4, desktop: 6 }}>
								<ProfileAvatar videoAvatar={arenaSelectedBot.videoAvatar} />
								<Box display="flex" gap={{ mobile: 4, desktop: 6 }} flexDirection="column">
									<Stack gap={1}>
										<Text variant="h2" fontWeight="medium">
											{arenaSelectedBot?.bot_name}
										</Text>
										<Box display="flex" gap={0.5}>
											{arenaSelectedBot?.badges.map((badge, index) => (
												<Badge key={index} badge={badge} />
											))}
										</Box>
									</Stack>
									<Button variant="primary" size="small">
										Follow
									</Button>
								</Box>
							</Box>
							<Box className={styles.botProfileWidgetsWrapper}>
								<BotProfileWidgets />
							</Box>
						</Box>
						<Box className={styles.botProfileGrid}>
							{/* PROFILE DETAILS */}
							<Box className={styles.botProfileDetails}>
								<TradesList trades={arenaSelectedBot?.trades} openPositions={arenaSelectedBot?.open_positions}>
									<Text as="span" variant="body1" color="neutral.100">
										Trades:{' '}
									</Text>
								</TradesList>
								<Text variant="body1" color="neutral.100">
									{arenaSelectedBot.description}
								</Text>
								{/* Strategy */}
								<Stack gap={2}>
									<Text variant="body1Bold">
										Strategy:{' '}
										<Text as="span" color="primary.500" fontSize="small">
											{arenaSelectedBot?.strategy}
										</Text>
									</Text>
									<Text variant="body2">{arenaSelectedBot?.strategy_description}</Text>
								</Stack>
							</Box>
							{/* PROFILE STRATEGY & RETURNS */}
							<Box className={styles.botProfileStrategyReturns}>
								{/* Returns */}
								<BotProfileChart data={arenaSelectedBot?.bot_details.cumulative_returns} />
							</Box>
							<Box>
								<BotProfileTabs data={data} />
							</Box>
						</Box>
					</Box>
				)}
			</Card>
		</Box>
	)
}

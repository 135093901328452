import "src/style/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/RightSidePanel/PanelApproach/PanelApproach.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/RightSidePanel/PanelApproach/PanelApproach.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA31U247iOBB95yusQSOBNEZJuLZ52QvdT/sB+zZy4grx4NhZx4Fsr/rft2wTCLvNKAK5XMd1OXVZXN5/vPyVJeSfCSFCto3ifzNSKuj3eMGVPGoqHdQtIwVoB9ZfO8t1Wxpbs3hU3MGfs2Vyvsy9+p1KLaBnJN1PPiaL6CENHj5/mcxHwOwOlE4azTAKRZLFpiXAW0DbI/DyCTi7gk3nRuhVQBedbY1lpDEyJnTXs8qcwZKGuypAS6kUI51Vs2l+/O6l7wExjncdkBcpXMWI58BTUIE8Vo6R7fpceTnnxeloTacFLYzy3s/czihtX/rTqTkH2rzhUpkLI5UUArS/a7gQUh8f4ZkO+NxYAZZaLmSH5Vntmp4k+PlDeGsGTiwgz/IM/la2xgv+Oh5hnAvLAasDkSeD9GhM4suXR3M8x4edC+acaRhJ/ElB6a5HG5NPYpTOmfomPIQsdQVWursGWwaTQOtSxKI23GII46fe9HMO7zwzH78s6NE7QxuzyTRb+48kX7/heXnItq8kHQvZWFgG4bft4ffDr2QVhHSZZmlG1mPYJmpW281qS3ZBSDL/oenk62Q+xJ2bUBN6gfwkHa15e2JESQ3c3kOclmXpSzgf6u6ffXsO8xa9JeSjDuUBRqAvVCfgYQ7peBA3/x1Eaxx2wSwRcJzvP52m1W304kCNnlODFZH6vh1ufrZP/KS7R08/t7KLc4itiNTFtlolsd+QFTrMmUYjXO1D1/6vNaroDHpHR+F0TQO2wLRuyrbiwo9f4gcJf9kS/6ZZenjZHsbFi2BnzQnode7Tpn+GuAY0fXt727yu90M2Ja+lwlX7B/SgBXnt+U3VyncsZLpYW6hvwYVV/AlBL4+bWxs9HmgetKbhhXSoTRbL0DOyp7lCt7Q2AljkjAojjjAMkbCmobjwnB/KXHV2himGtfdLDUJy0hYWQBOOsc9qbIyBiGyH1M2D24cIRzHmyhSnfbj7fKlc1wq9sjrsFroa5MEZjli8eGh1Qj4mH/8CsyfL/NkGAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var contentBox = 'wzj9q25';
export var glassWrapper = 'wzj9q29';
export var icon = 'wzj9q26';
export var iconExpanded = 'wzj9q27';
export var notch = 'wzj9q24';
export var panelApproach = 'wzj9q20';
export var panelApproachClosing = 'wzj9q23';
export var panelApproachExpanded = 'wzj9q21';
export var panelApproachOpening = 'wzj9q22';
export var title = 'wzj9q28';
export var topReflection = 'wzj9q2a';
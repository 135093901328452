import numeral from 'numeral'
import { tokens } from 'style/theme.css'

export const getColorForValue = (value: number | string): string => {
	const numericValue = numeral(value).value()

	if (numericValue === null || isNaN(numericValue)) {
		return 'inherit'
	}

	return numericValue >= 0 ? tokens.colors['primary.500'] : tokens.colors['warning.700']
}

import { ReactNode, useState } from 'react'
import { useWindowSize } from '@uidotdev/usehooks'
import * as Popover from '@radix-ui/react-popover'
import * as styles from './MainTitle.css'
import { Box } from 'components/layout/box'
import { breakpoints } from 'style/tokens/breakpoints'

import {
	BellIcon,
	CircleInfoIcon,
	CloseIcon,
	HamburgerTopMenu,
	PanelApproachIcon,
	PanelCalendarIcon,
	PanelLeaderboardIcon,
	SettingsIcon
} from 'assets/icons/icons'
import { BlockIcon } from 'components/icons/block-icon'
import { IconButton } from 'components/inputs/icon-button'
import { InfoButton } from './InfoButton'
import clsx from 'clsx'
import { useLocation, useNavigate } from 'react-router-dom'

interface MainTitleProps {
	children: ReactNode
	actionChildren?: ReactNode
	Dialog?: (children: ReactNode) => ReactNode
}

export const MainTitle = ({ children, actionChildren, Dialog }: MainTitleProps) => {
	const size = useWindowSize()
	const isTablet = size && size.width && size.width < Number(breakpoints.desktop)
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const navigate = useNavigate()

	const handleClick = () => {
		navigate('/mavbots-arena#leaderboard')
		setIsMenuOpen(false)
	}

	if (isTablet) {
		return (
			<Box className={styles.mainTitleWrapperMobile}>
				<Box display="flex" alignItems="center">
					<h1 className={styles.mainTitle}>{children}</h1>
					{Dialog && (
						<Box className={styles.iconWrapper}>
							{Dialog(
								<IconButton variant="closeButton" size="xSmall">
									<BlockIcon icon={CircleInfoIcon} size="small" color="neutral.500" />
								</IconButton>
							)}
						</Box>
					)}
				</Box>
				<Box>
					<Box className={styles.actionWrapper}>
						<IconButton variant="secondaryModern" size="medium">
							<BlockIcon icon={BellIcon} size="medium" color="primary.100" />
						</IconButton>
						<Popover.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
							{isMenuOpen && <div className={styles.overlay} />}
							<Popover.Trigger asChild>
								<div style={{ position: 'relative', zIndex: isMenuOpen ? '3' : 'auto' }}>
									<IconButton
										variant="secondaryModern"
										size="medium"
										className={clsx(styles.hamburgerMenu, isMenuOpen && styles.hamburgerMenuOpen)}>
										{isMenuOpen ? (
											<BlockIcon icon={CloseIcon} size="medium" color="primary.500" />
										) : (
											<BlockIcon icon={HamburgerTopMenu} size="medium" color="neutral.500" />
										)}
									</IconButton>
								</div>
							</Popover.Trigger>

							<Popover.Portal>
								<Popover.Content sideOffset={5}>
									<Box className={styles.dropdownMenu}>
										<IconButton variant="secondaryModern" size="small" className={styles.hamburgerMenuOpen}>
											<BlockIcon icon={PanelCalendarIcon} size="small" color="primary.500" />
										</IconButton>
										<IconButton
											variant="secondaryModern"
											size="small"
											className={styles.hamburgerMenuOpen}
											onClick={handleClick}>
											<BlockIcon icon={PanelLeaderboardIcon} size="small" color="primary.500" />
										</IconButton>
										<IconButton variant="secondaryModern" size="small" className={styles.hamburgerMenuOpen}>
											<BlockIcon icon={PanelApproachIcon} size="small" color="primary.500" />
										</IconButton>
										<IconButton variant="secondaryModern" size="small" className={styles.hamburgerMenuOpen}>
											<BlockIcon icon={SettingsIcon} size="small" color="primary.500" />
										</IconButton>
									</Box>
								</Popover.Content>
							</Popover.Portal>
						</Popover.Root>
					</Box>
				</Box>
			</Box>
		)
	}

	return (
		<Box className={styles.mainTitleWrapperDesktop}>
			<Box></Box>
			<h1 className={styles.mainTitle}>{children}</h1>
			{Dialog && <Box className={styles.iconWrapper}>{Dialog(<InfoButton />)}</Box>}
			{/* commented out, we dont need aciton items on desktop view */}
			{/* {actionChildren && <Box className={styles.actionWrapper}>{actionChildren}</Box>} */}
		</Box>
	)
}

import axios from 'axios'

const axiosClient = axios.create()
axiosClient.defaults.headers = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Credentials': true,
	'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
}

/* const jwt = JSON.parse(localStorage.getItem('okta-token-storage')) || '';
const token = jwt && jwt.accessToken.value; */

export const handleCustomerPortalHelper = accessToken => {
	return axiosClient.post(
		`${process.env.REACT_APP_API_SERVER}/stripe/create-customer-portal-session`,
		{},
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
}

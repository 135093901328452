import "src/style/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/typography/main-title/MainTitle.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/typography/main-title/MainTitle.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41UwY6bMBC95yss5ZJIcQQspFlyaaXdnPoTBg/gBmxkm4TdKv/esYEs2U2rSpjA88zj+c1Mtqyo2gwC8ntBCBemrdlbSkot+AEBVotSUmGhMSnJQVrQDnbbFEEMtkBzVXeNxICw0IR1VrmHw+K62A7c4T13UUP/D+5fnbGieENWRKRNiWlZDjQDewGQM9rI0xYYRo14B/z8NowSDc1hgi8gygoZ4iBwWC0k0GrEpNINqx2M8pVOyZnpFaXmuT+d2mrtNiz0llrNpCkwOCVd24LOmYHbpqkYV5eUBG3vV/SEt2UUvjx/e3FBqCA7CSTxwVarE9CL4LZCsW3/t4hR0PJ4PO5ek9tpCtaIGv37CT1ITl57NjPjyZsxeWegLlKCUbOI+HEVStbenz0q17OsxGe1yggrlMQ00YPvDKswb7AVCjs+TmcLgvPFvU9mO6ByQMbyU6lVJ/l0Sl1mbBVsyHhtE2/9OxWSQ5+SaKZl90mLBmw/cfblyFR/V40YVxjh7TN/6PlbxrmQZUr2QxkypTloqhkXHXZjPKBfzHK/lAsN+aBg6PybjSPZQ/F7L/7j/KlvR6Zp6T6Kjb4KdwGHckOWcRz/SPabQXqUJJtpofxovX7s47yEylfwewNcMGJyjXNDGLbMqhHy1n/RHn1ae1Xb+X/Af043IdePzGjMvBvGaRTHWfHjPh/06+L6BxkGL5N9BAAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionWrapper = 'afhpbe4';
export var dropdownMenu = 'afhpbe6';
export var hamburgerMenu = 'afhpbe7';
export var hamburgerMenuOpen = 'afhpbe8';
export var iconWrapper = 'afhpbe3';
export var mainTitle = 'afhpbe2';
export var mainTitleWrapperDesktop = 'afhpbe0';
export var mainTitleWrapperMobile = 'afhpbe1';
export var overlay = 'afhpbe5';
export const TopReflectionBackground = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto" viewBox="0 0 661 397" fill="none">
		<path
			opacity="0.4"
			d="M0 349.447V48C0 21.4903 21.4903 0 48 0H789C789 285.279 324.674 375.553 50.4061 396.736C23.0016 398.852 0 376.933 0 349.447Z"
			fill="url(#paint0_radial_4454_16445)"
			fillOpacity="0.4"
		/>
		<defs>
			<radialGradient
				id="paint0_radial_4454_16445"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(1.16624e-05 20) rotate(0.472964) scale(790.14 314.948)">
				<stop offset="0.0001" stopColor="#D9D9D9" />
				<stop offset="0.0002" stopColor="#D9D9D9" />
				<stop offset="1" stopColor="#D9D9D9" stopOpacity="0.01" />
			</radialGradient>
		</defs>
	</svg>
)

import { Outlet as ReactRouterOutlet } from 'react-router-dom'

import { Box } from 'components/layout/box'

import * as styles from './Outlet.css'

export const Outlet = () => (
	<Box className={styles.outlet}>
		<ReactRouterOutlet />
	</Box>
)

import { handleCustomerPortalHelper } from './stripe'

export const redirectToCustomerPortal = async (getAccessToken: () => string | undefined): Promise<void> => {
	try {
		const accessToken = getAccessToken()
		if (!accessToken) throw new Error('No access token found')

		const res = await handleCustomerPortalHelper(accessToken)
		if (res.status === 200 && res.data?.data?.url) {
			window.location.href = res.data.data.url
		} else {
			console.error('Error: Unexpected response status', res.status)
		}
	} catch (err) {
		console.error('Error redirecting to customer portal', err)
	}
}

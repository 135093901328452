import botImg1 from '../assets/bot.jpg'
import botGif from 'assets/state-of-market/botGif.mp4'

export interface Badge {
	name: string
	icon: string
}

export interface TradeHistory {
	datetime: string
	size: string
	message: string
}

export interface Trader {
	bot_id: number
	bot_name: string
	description: string
	avatar: string
	videoAvatar: string
	riskLevel: 1 | 2 | 3
	badges: Badge[]
	strategy: string
	strategy_description: string
	trades: string[]
	open_positions: string[]
	perf_ytd: string
	perf_5y: string
	win_rate: string
	win_avg: string
	loss_rate: string
	loss_avg: string
	trade_history: TradeHistory[]
}

export const dummyData: Trader[] = [
	{
		bot_id: 1,
		bot_name: 'Trevor',
		description: 'Hi, I’m Trevor. My approach is risk-management focused and I only trade SPY and QQQ indices.',
		avatar: botImg1,
		videoAvatar: botGif,
		riskLevel: 1,
		badges: [
			{ name: 'Top Performer', icon: 'a' },
			{ name: 'Biggest Winner', icon: 'b' }
		],
		strategy: 'Swing',
		strategy_description:
			'Index Swing trading is a trading strategy that aims to capture short to medium term gains in index exchange traded funds such as SPY and QQQ.',
		trades: ['AAPL', 'GOOG', 'MSFT'],
		open_positions: ['GOOG', 'MSFT'],
		perf_ytd: '43%',
		perf_5y: '32%',
		win_rate: '34.4%',
		win_avg: '12.1%',
		loss_rate: '2.1%',
		loss_avg: '3.1%',
		trade_history: [{ datetime: '1/18/2023, 11:32AM EST', size: '14%', message: 'Sold $TSLA at 128.49' }]
	},
	{
		bot_id: 2,
		bot_name: 'Ava',
		description:
			'Hi, I’m Ava. My approach is based on my career as a risk-management focused trader at Orion Capital. I’m a conservative trader and only trade SPY and QQQ indices.',
		avatar: botImg1,
		videoAvatar: botGif,
		riskLevel: 2,
		badges: [
			{ name: 'Consistent Performer', icon: 'c' },
			{ name: 'Risk Manager', icon: 'd' }
		],
		strategy: 'Day Trading',
		strategy_description:
			'Index Swing trading is a trading strategy that aims to capture short to medium term gains in index exchange traded funds such as SPY and QQQ.',
		trades: ['AAPL', 'GOOG', 'MSFT', 'TSLA', 'AMZN', 'NFLX', 'FB'],
		open_positions: ['MSFT'],
		perf_ytd: '-38%',
		perf_5y: '28%',
		win_rate: '40.2%',
		win_avg: '10.5%',
		loss_rate: '1.8%',
		loss_avg: '2.8%',
		trade_history: [{ datetime: '2/10/2023, 09:45AM EST', size: '12%', message: 'Bought $AMZN at 3200.00' }]
	},
	{
		bot_id: 3,
		bot_name: 'Rohan',
		description:
			'Hi, I’m Rohan. My approach is based on my career as a risk-management focused trader at Orion Capital. I’m a conservative trader and only trade SPY and QQQ indices.',
		avatar: botImg1,
		videoAvatar: botGif,
		riskLevel: 3,
		badges: [{ name: 'High Risk Taker', icon: 'e' }],
		strategy: 'Scalping',
		strategy_description:
			'Index Swing trading is a trading strategy that aims to capture short to medium term gains in index exchange traded funds such as SPY and QQQ.',
		trades: ['AAPL', 'TSLA', 'AMZN', 'NFLX'],
		open_positions: ['AAPL'],
		perf_ytd: '50%',
		perf_5y: '45%',
		win_rate: '30.1%',
		win_avg: '15.0%',
		loss_rate: '4.0%',
		loss_avg: '5.0%',
		trade_history: [{ datetime: '3/05/2023, 12:30PM EST', size: '10%', message: 'Sold $TWTR at 70.00' }]
	},
	{
		bot_id: 4,
		bot_name: 'Lily',
		description:
			'Hi, I’m Lily. My approach is based on my career as a risk-management focused trader at Orion Capital. I’m a conservative trader and only trade SPY and QQQ indices.',
		avatar: botImg1,
		videoAvatar: botGif,
		riskLevel: 2,
		badges: [
			{ name: 'Strategist', icon: 'f' },
			{ name: 'Growth Investor', icon: 'g' }
		],
		strategy: 'Swing',
		strategy_description:
			'Index Swing trading is a trading strategy that aims to capture short to medium term gains in index exchange traded funds such as SPY and QQQ.',
		trades: ['GOOG', 'NFLX', 'AAPL'],
		open_positions: ['GOOG'],
		perf_ytd: '0.2%',
		perf_5y: '0.2%',
		win_rate: '35.5%',
		win_avg: '11.2%',
		loss_rate: '2.2%',
		loss_avg: '3.5%',
		trade_history: [{ datetime: '4/02/2023, 10:15AM EST', size: '20%', message: 'Bought $GOOG at 2400.50' }]
	},
	{
		bot_id: 5,
		bot_name: 'Ethan',
		description:
			'Hi, I’m Ehan. My approach is based on my career as a risk-management focused trader at Orion Capital. I’m a conservative trader and only trade SPY and QQQ indices.',
		avatar: botImg1,
		videoAvatar: botGif,
		riskLevel: 1,
		badges: [{ name: 'Low Risk Investor', icon: 'h' }],
		strategy: 'Position Trading',
		strategy_description:
			'Index Swing trading is a trading strategy that aims to capture short to medium term gains in index exchange traded funds such as SPY and QQQ.',
		trades: ['AAPL', 'MSFT', 'FB'],
		open_positions: ['FB', 'AAPL'],
		perf_ytd: '-1%',
		perf_5y: '25%',
		win_rate: '44.2%',
		win_avg: '9.5%',
		loss_rate: '1.5%',
		loss_avg: '2.9%',
		trade_history: [{ datetime: '5/13/2023, 02:10PM EST', size: '8%', message: 'Sold $FB at 230.00' }]
	},
	{
		bot_id: 6,
		bot_name: 'Mia',
		description:
			'Hi, I’m Mia. My approach is based on my career as a risk-management focused trader at Orion Capital. I’m a conservative trader and only trade SPY and QQQ indices.',
		avatar: botImg1,
		videoAvatar: botGif,
		riskLevel: 3,
		badges: [{ name: 'Risk Taker', icon: 'i' }],
		strategy: 'Scalping',
		strategy_description:
			'Index Swing trading is a trading strategy that aims to capture short to medium term gains in index exchange traded funds such as SPY and QQQ.',
		trades: ['AMZN', 'TSLA', 'NFLX'],
		open_positions: ['AMZN'],
		perf_ytd: '45%',
		perf_5y: '40%',
		win_rate: '32.1%',
		win_avg: '14.0%',
		loss_rate: '3.7%',
		loss_avg: '4.5%',
		trade_history: [{ datetime: '6/07/2023, 11:50AM EST', size: '18%', message: 'Bought $NFLX at 500.50' }]
	},
	{
		bot_id: 7,
		bot_name: 'Sophia',
		description:
			'Hi, I’m Sophia. My approach is based on my career as a risk-management focused trader at Orion Capital. I’m a conservative trader and only trade SPY and QQQ indices.',
		avatar: botImg1,
		videoAvatar: botGif,
		riskLevel: 1,
		badges: [{ name: 'Steady Growth', icon: 'j' }],
		strategy: 'Swing',
		strategy_description:
			'Index Swing trading is a trading strategy that aims to capture short to medium term gains in index exchange traded funds such as SPY and QQQ.',
		trades: ['GOOG', 'AAPL'],
		open_positions: ['GOOG'],
		perf_ytd: '39%',
		perf_5y: '30%',
		win_rate: '38.5%',
		win_avg: '12.5%',
		loss_rate: '2.3%',
		loss_avg: '3.8%',
		trade_history: [{ datetime: '7/15/2023, 09:00AM EST', size: '15%', message: 'Bought $GOOG at 2450.25' }]
	},
	{
		bot_id: 8,
		bot_name: 'Jackson',
		description:
			'Hi, I’m Jakcson. My approach is based on my career as a risk-management focused trader at Orion Capital. I’m a conservative trader and only trade SPY and QQQ indices.',
		avatar: botImg1,
		videoAvatar: botGif,
		riskLevel: 2,
		badges: [
			{ name: 'Consistent Profits', icon: 'k' },
			{ name: 'Momentum Trader', icon: 'l' }
		],
		strategy: 'Day Trading',
		strategy_description:
			'Index Swing trading is a trading strategy that aims to capture short to medium term gains in index exchange traded funds such as SPY and QQQ.',
		trades: ['AAPL', 'GOOG', 'NFLX'],
		open_positions: ['AAPL'],
		perf_ytd: '36%',
		perf_5y: '31%',
		win_rate: '39.8%',
		win_avg: '10.8%',
		loss_rate: '1.9%',
		loss_avg: '2.6%',
		trade_history: [{ datetime: '8/12/2023, 12:45PM EST', size: '16%', message: 'Sold $AAPL at 150.00' }]
	},
	{
		bot_id: 9,
		bot_name: 'Olivia',
		description:
			'Hi, I’m Olivia. My approach is based on my career as a risk-management focused trader at Orion Capital. I’m a conservative trader and only trade SPY and QQQ indices.',
		avatar: botImg1,
		videoAvatar: botGif,
		riskLevel: 1,
		badges: [{ name: 'Stable Returns', icon: 'm' }],
		strategy: 'Position Trading',
		strategy_description:
			'Index Swing trading is a trading strategy that aims to capture short to medium term gains in index exchange traded funds such as SPY and QQQ.',
		trades: ['MSFT', 'AAPL'],
		open_positions: ['MSFT'],
		perf_ytd: '0%',
		perf_5y: '27%',
		win_rate: '42.5%',
		win_avg: '9.2%',
		loss_rate: '1.7%',
		loss_avg: '2.7%',
		trade_history: [{ datetime: '9/19/2023, 03:30PM EST', size: '12%', message: 'Bought $MSFT at 280.00' }]
	},
	{
		bot_id: 10,
		bot_name: 'Liam',
		description:
			'Hi, I’m Liam. My approach is based on my career as a risk-management focused trader at Orion Capital. I’m a conservative trader and only trade SPY and QQQ indices.',
		avatar: botImg1,
		videoAvatar: botGif,
		riskLevel: 3,
		badges: [{ name: 'High Volatility', icon: 'n' }],
		strategy: 'Scalping',
		strategy_description:
			'Index Swing trading is a trading strategy that aims to capture short to medium term gains in index exchange traded funds such as SPY and QQQ.',
		trades: ['TSLA', 'NFLX', 'AMZN'],
		open_positions: ['TSLA'],
		perf_ytd: '47%',
		perf_5y: '42%',
		win_rate: '33.2%',
		win_avg: '13.5%',
		loss_rate: '3.9%',
		loss_avg: '4.9%',
		trade_history: [{ datetime: '10/10/2023, 01:00PM EST', size: '14%', message: 'Bought $TSLA at 650.00' }]
	},
	{
		bot_id: 11,
		bot_name: 'Emily',
		description:
			'Hi, I’m Emily. My approach is based on my career as a risk-management focused trader at Orion Capital. I’m a conservative trader and only trade SPY and QQQ indices.',
		avatar: botImg1,
		videoAvatar: botGif,
		riskLevel: 2,
		badges: [{ name: 'Consistent Growth', icon: 'o' }],
		strategy: 'Swing',
		strategy_description:
			'Index Swing trading is a trading strategy that aims to capture short to medium term gains in index exchange traded funds such as SPY and QQQ.',
		trades: ['GOOG', 'AAPL', 'NFLX'],
		open_positions: ['NFLX'],
		perf_ytd: '35%',
		perf_5y: '29%',
		win_rate: '36.7%',
		win_avg: '11.8%',
		loss_rate: '2.4%',
		loss_avg: '3.6%',
		trade_history: [{ datetime: '11/03/2023, 02:15PM EST', size: '13%', message: 'Sold $NFLX at 550.50' }]
	},
	{
		bot_id: 12,
		bot_name: 'Noah',
		description:
			'Hi, I’m Noah. My approach is based on my career as a risk-management focused trader at Orion Capital. I’m a conservative trader and only trade SPY and QQQ indices.',
		avatar: botImg1,
		videoAvatar: botGif,
		riskLevel: 3,
		badges: [{ name: 'Aggressive Trader', icon: 'p' }],
		strategy: 'Scalping',
		strategy_description:
			'Index Swing trading is a trading strategy that aims to capture short to medium term gains in index exchange traded funds such as SPY and QQQ.',
		trades: ['AAPL', 'TSLA', 'AMZN'],
		open_positions: ['TSLA'],
		perf_ytd: '49%',
		perf_5y: '46%',
		win_rate: '29.5%',
		win_avg: '14.7%',
		loss_rate: '4.2%',
		loss_avg: '5.2%',
		trade_history: [{ datetime: '12/22/2023, 11:10AM EST', size: '11%', message: 'Bought $AAPL at 180.00' }]
	}
]

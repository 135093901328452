export const  getRandomSpecialCharacters = (length: number): string => {
	const specialChars = '!@#$%1234567890&*()+-=[]{}|;\':",.<>/?`~'
	let result = ''
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * specialChars.length)
		result += specialChars[randomIndex]
	}
	return result
}


import { ReactNode, createContext, useContext, useMemo, useState, FC } from 'react'

// Interface for the context state
export interface Context {
	activeTab: string
	onActiveTabChange: (tab: string) => void
	tabs: string[]
}

// Create a context with an undefined initial value
const TabsContext = createContext<Context | undefined>(undefined)

interface ProviderProps {
	children: ReactNode
	defaultTab?: string
	tabs: string[]
}

// TabsProvider component
export const TabsProvider: FC<ProviderProps> = ({ children, defaultTab = '', tabs }) => {
	const [activeTab, setActiveTab] = useState<string>(defaultTab)

	const value = useMemo(
		() => ({
			activeTab,
			onActiveTabChange: setActiveTab,
			tabs
		}),
		[activeTab, tabs]
	)

	return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>
}

// Custom hook to use the TabsContext
export const useTabsContext = () => {
	const context = useContext(TabsContext)
	if (!context) {
		throw new Error('useTabsContext must be used within a TabsProvider')
	}
	return context
}

import React from 'react'

import { Box } from 'components/layout/box'
import * as styles from './RiskChip.css'
import { SpeedUpFillIcon } from 'assets/icons/icons'
import { BlockIcon } from 'components/icons/block-icon'
import { tokens } from 'style/theme.css'

type RiskLevel = 1 | 2 | 3

const getRiskAttributes = (riskLevel: RiskLevel) => {
	switch (riskLevel) {
		case 1:
			return { text: 'Low Risk', color: tokens.colors['primary.500'] }
		case 2:
			return { text: 'Medium Risk', color: tokens.colors['warning.500'] }
		case 3:
			return { text: 'High Risk', color: tokens.colors['destructive.700'] }
		default:
			return { text: 'Unknown Risk', color: tokens.colors['neutral.500'] }
	}
}

interface RiskChipProps {
	riskLevel: RiskLevel
}

export const RiskChip = ({ riskLevel }: RiskChipProps) => {
	const { text, color } = getRiskAttributes(riskLevel)
	return (
		<Box className={styles.riskChip} style={{ color }}>
			<BlockIcon icon={SpeedUpFillIcon} size="xSmall" />
			{text}
		</Box>
	)
}
